import React,{Component,Fragment} from 'react';
import Category from './Category';
import './offer.css';
 // const proxy = require('http-proxy-middleware');

 
class Offer extends Component{
	constructor(props){
		super(props);
		this.state={
      offers:[],

			isLoaded:false,
		}
	}
	componentDidMount(){

        // window.scrollTo(0, 479);
		fetch('lefufooold/api/products')
		.then(response => response.json())
		.then(json => {
            console.log(json);
		    console.log(localStorage.getItem('apiData'));
            if (localStorage.getItem('apiData')) {
             localStorage.setItem('apiData', JSON.stringify(json));
            }
            else{
                localStorage.setItem('apiData', JSON.stringify(json));
                console.log(json);
            }

      this.setState({
        isLoaded:true,
        items:json,
      })
      
    });
   
   

	}
  render(){
    var {isLoaded} =this.state;
    var items=JSON.parse(localStorage.getItem('apiData'));

  	if (!isLoaded){
  	   return(
         

          <div>
          no data from api
          </div>
          

        );
  	}
  	else{
  	 return(
      
     <section class="sec-main-meal ">
   <div id="main-meal" class="section scrollspy">
   
    <div class="containe">
       
       
       
          <div id="test2" class="">
              <div id="main-meal" class="main-meal-header section scrollspy">
              
              {items.map(item =>(
               <Fragment>
            <div className={""}><span class="green-text offer-head">{item.offer_name}  {item.offer_date}</span> </div>
                {item.category.map(offerItem =>(
          
                  <Category categoryId={offerItem.category_id} categoryName={offerItem.category_name} offerName={item.offer_name} offerId={item.offer_id}  handleAddToCart={this.props.handleAddToCart} categories={offerItem}></Category>
 
                   ))}

              </Fragment>
                   ))}

                </div>
          

  </div>
</div>
        </div>
  
 </section>
  
      );
  	}
    
  }
}
export default Offer;