import React, {Component,Fragment} from 'react';
import './login.css';
import {PostData} from "./services/PostData";
import {Redirect} from "react-router-dom";
import { BrowserRouter as Router, Route, Link , browserHistory} from "react-router-dom";
import DetailsHeader from "./details/DetailsHeader";
import LoginVerify from "./LoginVerify";
import FacebookLogin from "react-facebook-login";
import {GetProfile} from "./services/GetProfile";
import {FbLogin} from "./services/FbLogin";
import { GoogleLogin } from 'react-google-login';
import {GgLogin} from "./services/GgLogin";
class Login extends Component {
    constructor(){
        super();
        this.state={
            mobile:'',
            redirect: false,
            fromheader:false,
            isLoggedIn:false,
            responseFB:'',
            responseGoogle:'',
            redirectFB:false,
            redirectGoogle:false
        }
        this.login=this.login.bind(this);
        this.onChangeInput=this.onChangeInput.bind(this);
        this.loginGoogle=this.loginGoogle.bind(this);


    };
componentDidMount() {

    // console.log(this.props.location.state);
    const { fromHeader } = this.props.location.state;
    // console.log(fromHeader);
    if(fromHeader==true){
        this.setState({'fromheader':true});
    }
    else{
        this.setState({'fromheader':false});
    }
}





    login(e){
e.preventDefault();
    PostData(this.state).then((result) => {
        let responseJson = result;
        console.log(responseJson);

        if(responseJson){
            sessionStorage.setItem('userData',JSON.stringify(responseJson));
            this.setState({redirect: true});

        }

        console.log(this.state);
    });
}
onChangeInput(e){
    this.setState({[e.target.name]:e.target.value});

}

componentClicked=()=>{
    console.log("clicked");

}
responseFacebook=response=>{
    console.log(response);
   this.setState({responseFB:response});
    console.log(this.state.responseFB);
    console.log(response.accessToken);
    // localStorage.setItem('session',JSON.stringify(response.accessToken));
    localStorage.setItem('sessionData',JSON.stringify(response));
    var saveFbData={};
    saveFbData.response=response;

    FbLogin(saveFbData).then((result) => {
        let responseJson = result;
        console.log(responseJson['token']);


        // console.log(responseJson['status']);
        if(responseJson['status']=="successfull"){
            this.setState({redirectFB:true});
            localStorage.setItem('session',JSON.stringify(responseJson['token']));
        }
        else{
            this.setState({redirectFB:false});
            // sessionStorage.setItem('userData',JSON.stringify(responseJson));
            // this.props.hostory.push('/paymentmethod');
            // alert("payment not completed");
            alert("error");

        }

        // console.log(this.state);
    });
    console.log(response.accessToken);

}

    loginGoogle(response) {
        console.log(response);
        this.setState({responseGoogle:response});
        console.log(this.state.responseGoogle);
        // console.log(response.accessToken);
        // localStorage.setItem('session',JSON.stringify(response.accessToken));
        // localStorage.setItem('sessionData',JSON.stringify(response));
        localStorage.setItem('sessionData',JSON.stringify(response));
        var saveGoogleData={};
        saveGoogleData.response=response;


        GgLogin(saveGoogleData).then((result) => {
            let responseJson = result;
            console.log(responseJson);


            // console.log(responseJson['status']);
            if(responseJson['status']=="successfull"){
                this.setState({redirectGoogle:true});
                localStorage.setItem('session',JSON.stringify(responseJson['token']));
            }
            else{
                this.setState({redirectFB:false});
                // sessionStorage.setItem('userData',JSON.stringify(responseJson));
                // this.props.hostory.push('/paymentmethod');
                // alert("payment not completed");
                alert("error");

            }

            // console.log(this.state);
        });
    }
render() {
    const responseGoogle = (response) => {
        console.log(response);
        this.loginGoogle(response);
    }

    let fbContent;
    if (this.state.isLoggedIn){
        fbContent=null
    }
    else{
        fbContent=( <FacebookLogin
            appId="758678571279127"
            autoLoad={false}
            fields="name,email,picture"
            onClick={this.componentClicked}
            callback={this.responseFacebook} />);
    }
    if (this.state.redirect && this.state.fromheader) {
        return (<Redirect to={{
            pathname: '/loginverify',
            state: {fromHeader: 'true'}
        }}/>);
    }
        else  {
            if(this.state.redirect && this.props.location.state.fromHeader){
                return (<Redirect to={{
                    pathname: '/loginverify',
                    state: { fromHeader: 'false' }
                }}/>);
            }
        // this.props.history.push(`/loginverify`);
    }
        if(this.state.redirectFB && (this.state.fromheader)){
            return (<Redirect to={{
                pathname: '/profile',
                state: { fbDetails: this.state.responseFB }
            }}
            />);
        }

    if(this.state.redirectFB && (!this.state.fromheader)){
        return (<Redirect to={{
            pathname: '/basket',
            state: { fbDetails: this.state.responseFB }
        }}
        />);
    }

    if(this.state.redirectGoogle && (this.state.fromheader)){
        return (<Redirect to={{
            pathname: '/profile',
            state: { fbDetails: this.state.responseGoogle }
        }}
        />);
    }

    if(this.state.redirectGoogle && (!this.state.fromheader)){
        return (<Redirect to={{
            pathname: '/basket',
            state: { fbDetails: this.state.responseGoogle }
        }}
        />);
    }




    console.log(this.state.fromheader);

    return (

        <Fragment>
            <div className="container">
                <div className="details-header">
                    <div className="back-button">

                        <Link to="/"><div className="back-image"><img src="img/icons/back.png"/></div></Link>
                    </div>
                    <div className="button-text">
                        back to main
                    </div>


                </div>

            </div>
    <div class="full-basket-container-login">
        <div class="container-small-of-login">
            <div class="container1">

                <div className="space-custom"></div>
                <span className="login-heading">Login or SignUp</span>

                <div className="space-big"></div>

                        <form onSubmit={this.login}>
                            {/*<label>Enter Mobile Number</label>*/}
                            <span><input className="login-mobile-input" type="text" name="mobile" placeholder="Enter Mobile Number" onChange={this.onChangeInput}/></span>
                            <span><button  className="btn green accent-4 mobile-input-button" type="submit"><span className="font-weight-600">submit</span></button></span>
                        </form>


                {fbContent}
                {/*<button  className="btn fb-blue facebook-login-button"><span className="font-weight-600">Facebook</span></button>*/}
                {/*        <button className="btn google-red facebook-login-button"><span className="font-weight-600">Google</span></button>*/}

                <div className="space-big"></div>
                {/*<GoogleLogin*/}
                {/*    clientId="245800140435-cgqqqd0cjjn8752gmb0ruai8i2m7v7nm.apps.googleusercontent.com"*/}
                {/*    buttonText="Login with google"*/}
                {/*    onSuccess={responseGoogle}*/}
                {/*    onFailure={responseGoogle}*/}
                {/*    cookiePolicy={'single_host_origin'}*/}
                {/*    className={"google-login-button facebook-login-button"}*/}
                {/*/>*/}

                <GoogleLogin
                    clientId="245800140435-cgqqqd0cjjn8752gmb0ruai8i2m7v7nm.apps.googleusercontent.com"
                    render={renderProps => (
                        <button className="btn google-red facebook-login-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>Login with Google</button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />

            </div>
            <div className="space-big"></div>
        </div>
    </div>

    </Fragment>
    );
}

}
export default Login;
