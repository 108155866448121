import React,{Component} from 'react';
import {Link, Switch} from "react-router-dom";
import './Foods.css'
import './foodscss.css';
import Details from './details/Details';
import {Route} from "react-router";
import FullBasket from "./FullBasket";
class Foods extends Component{
  
  constructor(props){
    super(props);
     this.state = {
         soldOutHide:'sold-out-hide'
     };

  }
  componentDidMount() {
      if(this.props.types.pricequantity==0){
          this.setState({soldOutHide:'sold-out'});
      }
  }

    render(){

      var rating=this.props.types.rating;
      // console.log(typeof rating);
      var i;
      var html="";
      for(i=0; i<rating; i++){
        html += "<i class='material-icons'>star</i>";
        html+="";
      }
      // console.log(html);
    return(
      <div class="col s12 m6 l4 xl3">
    	   <div class="card hoverable spacing-top ">
                      <div class="card-image">
                          <span class="new badge red" data-badge-caption={this.props.typeName}></span>
                          <div className={this.state.soldOutHide}>
                              <img className={"sold-out-img"} src={"img/icons/cross-out.png"}/>
                          </div>
                        <img class="responsive-img" src={this.props.types.imagepath} />
                        
                        
            
                      </div>
                      <div class="card-content">
                      <a class="btn-floating halfway-fab waves-effect waves-light" onClick={(e)=>this.props.handleAddToCart(e,this.props.offerId,this.props.offerName,this.props.categoryId,this.props.categoryName,this.props.typeId,this.props.types)}><i class="material-icons">
add_shopping_cart
</i></a>
                          <div class="starred">

                              <p>
                                  <div dangerouslySetInnerHTML={{__html: html}}/>
                                  {/*<i class="material-icons">star</i>*/}
                                  {/*<i class="material-icons">star</i>*/}
                                  {/*<i class="material-icons">star</i>*/}
                                  {/*<i class="material-icons">star</i>*/}
                                  {/*<i class="material-icons">star</i>*/}
                                </p>
                          </div>
                          <b><p class="cart-product-name"> {this.props.types.productname}</p></b>
                        <p> Price : <span><span class="money">{this.props.types.price}</span> taka</span></p>
                        <p> Weight :<span class="money">{this.props.types.weight}</span> kg</p>
                        <p> Size :<span class="money">{this.props.types.size}</span></p>
                      </div>
                  
                      <div class="card-action">
                          <a className="btn-floating halfway-fab green accent-4"
                             onClick={(e) => this.props.handleAddToCart(e, this.props.offerId, this.props.categoryId, this.props.typeId, this.props.types)}><i
                              className="material-icons">
                              local_grocery_store
                          </i></a>
      <Link to={{
  pathname: '/details',
  state: {
    foods: this.props.types,
      paramofferid:this.props.offerId,
      paramcategoryid:this.props.categoryId,
      paramtypeid:this.props.typeId
  }
}}>See details</Link>
                          {/*<Switch>*/}
                          {/*    */}
                          {/*    <Route path="/life" render={props => <FullBasket/>} />*/}
                          {/*</Switch>*/}
                        </div>
                    </div>
                    </div>

      );
  }
}
export default Foods;