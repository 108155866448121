import React,{Component,Fragment} from 'react';

import './starrating.css';
import {PostData} from "../services/PostData";
import {CanReview} from "../services/CanReview";
import {GiveReview} from "../services/GiveReview";


class Reviews extends Component{
    constructor(props){
        super(props);
        this.state={
            canreview:false,
            rateValue:''
        }
        this.saveReview=this.saveReview.bind(this);
        this.handleChange=this.handleChange.bind(this);
    }
    handleChange(event){
        this.setState({rateValue: event.target.value});
        console.log(this.state.rateValue);
    }
    saveReview(event){
event.preventDefault();
        var token=JSON.parse(localStorage.getItem('session'));
        var productid=this.props.productid;
        var reviewDetails={};
        reviewDetails.token=token;
        reviewDetails.productid=productid;
        reviewDetails.reviewvalue=this.state.rateValue;
        GiveReview(reviewDetails).then((result) => {
            let responseJson = result;
            console.log(responseJson);

            if(responseJson.status=="successfull"){
                alert("review stored successfully");

            }

            console.log(this.state);
        });

    }
    componentDidMount() {
        var token=JSON.parse(localStorage.getItem('session'));
        CanReview(token).then((result) => {
            let responseJson = result;
            console.log(responseJson);

            if(responseJson.status=="successfull"){
                this.setState({'canreview':true});

            }

            console.log(this.state);
        });
    }

    render(){
        if(this.state.canreview){
            return(


                <div className="row half-width">

                    <form onSubmit={this.saveReview}>
                        <div className="input-field col  l12 s6">
                            <input id="comment" type="text" onChange={this.handleChange} className="validate"/>
                            <label htmlFor="comment_box">Comment</label>
                        </div>
                        <button type="submit" className="btn green accent-4">Give Feedback</button>

                    </form>
                    <div className="space-big"></div>
                </div>



            );
        }
        else{
            return(<div> </div>);
        }
    }
}
export default Reviews;