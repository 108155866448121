export function ShowDeliveryTime() {


    let BaseURL = 'lefufooold/api/deliverytimes';
    //let BaseURL = 'http://localhost/PHP-Slim-Restful/api/';
    console.log(BaseURL);
    return new Promise((resolve, reject) =>{
        fetch(BaseURL,  {
            method: 'GET',


        }, {mode: 'cors'})
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
                console.log(res);


            })
            .catch((error) => {
                reject(error);
            });
    });
}