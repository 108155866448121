import React,{Component,Fragment} from 'react';

class OrderCompleted extends Component{
    constructor(props){
        super(props);
        this.redirect=this.redirect.bind(this);




    }
    redirect(){
        this.props.history.push('/');
    }
    render(){


        return(



            <Fragment>
               <div>
                   your order is completed
               </div>
                <h5> pin :777890</h5>

                please keep this for delivery

                <button onClick={this.redirect}>Go to home</button>

            </Fragment>


        );
    }
}
export default OrderCompleted;