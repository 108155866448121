import React,{Component,Fragment} from 'react';

import './comments.css';

import StarRating from "./StarRating";
import Reviews from "./Reviews";
class Comments extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(


            <div id="comments" className="section scrollspy">
                <div className="comments-title">
                    <h4>Comments</h4>
                </div>
                <div className="comment-subtitle">
                    <p className="t">What people say about us</p>
                </div>

                <div className="comment-box">
                    {/*<div className="profile-image">*/}
                    {/*    <img src="img/candy.jpg" className="circle" alt=""/>*/}
                    {/*</div>*/}
                    {this.props.comments.map(comment=>(
                        <Fragment>
                            <hr/>
                            <div className="comment-space"></div>
                        <div className="comment-content">
                            <div className="profile-image">
                                <img src="img/icons/avatar.png" className="circle" alt=""/>
                            </div>

                            <div className="name">
                                <span className="comment-username">{comment.user_name}</span>
                            </div>



                        </div>
                            <div className="comment-space"></div>
                            <div className="content">
                                {comment.comment}
                            </div>
                            <div className="comment-space"></div>
                        </Fragment>
                    ))}
                    <div>
                        <Reviews productid={this.props.productid}/>
                    </div>


                </div>


            </div>



        );
    }
}
export default Comments;