import React,{Component} from 'react';
import Offer from './Offer';
import Header from './Header';

import Basket from './Basket';

import FullBasket from "./FullBasket";
import {AddToCart} from "./services/AddToCart";
import {RemoveFromCart} from "./services/RemoveFromCart";

class App extends Component{
	constructor(props){
    super(props);
    
		this.state={
      cartItems:[],
     
      offers1: [],
      offers: [],
      displayBasket : false,
            orderState:[],
            empty:[],
            orderidarray:[],
            totalPrice:0
		};
		console.log(this.state.orderState);
    this.handleAddToCart=this.handleAddToCart.bind(this);
    this.handleRemoveFromCart=this.handleRemoveFromCart.bind(this);
    this.getOfferName=this.getOfferName.bind(this);
    this.getCategoryName=this.getCategoryName.bind(this);
    this.searchOrderIndex=this.searchOrderIndex.bind(this);
    this.searchCategoryIndex=this.searchCategoryIndex.bind(this);
    this.searchTypeIndex=this.searchTypeIndex.bind(this);
    this.funcDisplayBasket=this.funcDisplayBasket.bind(this);

  }
  getOfferName(offerId){
    var items=JSON.parse(localStorage.getItem('apiData'));
      var offername;
      // return "today";

      console.log(offerId);
    for(var i=0; i<items.length; i++){

        if(items[i].offer_id==offerId){
            offername=items[i].offer_name;
            console.log(offername);
            return offername;
        }
    }
  }
     MyComponent = () => {
	    console.log("exported");
    }

  getCategoryName(offerId,categoryId){
      var items=JSON.parse(localStorage.getItem('apiData'));
      // return "new";
    console.log(items);

      console.log(categoryId);
      console.log(offerId);
    for(var i=0; i<items.length; i++){


        
      if(items[i].offer_id==offerId){
          console.log(items.length);
        for(var j=0; j<20; j++){

          if(items[i].category[j].category_id==categoryId) {
             var categoryname;
             categoryname=items[i].category[j].category_name;
                console.log(categoryname);
                return categoryname;
         }
         }
      }

        
    }
  }


    componentDidMount() {

	    // localStorage.clear();
        // console.log();
        if(sessionStorage.getItem('firstLoad')==null){
            localStorage.clear();
        }
        sessionStorage.setItem('firstLoad','loaded');
        console.log(sessionStorage.getItem('firstLoad'));

      // localStorage.setItem('cartItems',null);
      // var cart={};
      // cart.list=[];
      //   localStorage.setItem('cartItems', JSON.stringify(cart));
      var ifReLoad=true;
        // const { fromDetails } = this.props.location.state;
    this.setState({ offers1: JSON.parse(localStorage.getItem('apiData')) });


  }
  funcDisplayBasket (e,i){


      if(i==0){
          this.setState({displayBasket:false});
      }
      else{
          this.setState({displayBasket:true});
      }


}
    searchOrderIndex(offerArr,orderId){
	     var orderVar=offerArr;
	     console.log(offerArr);
	     var obj=orderVar.find(function (x) {
	         console.log(x);
             return x.order_id==orderId;
             }

         );
	     var index=-1;
	     if (obj!=undefined)
             index=orderVar.indexOf(obj);
	     return index;
    }
    searchCategoryIndex(offerArr,orderId){
        var orderVar=offerArr;
        var obj=orderVar.find(function (x) {
                return x.category_id==orderId;
            }

        );
        var index=-1;
        if (obj!=undefined)
            index=orderVar.indexOf(obj);
        return index;
    }
    searchTypeIndex(offerArr,orderId){
        var orderVar=offerArr;
        var obj=orderVar.find(function (x) {
                return x.type_id==orderId;
            }

        );
        var index=-1;
        if (obj!=undefined)
            index=orderVar.indexOf(obj);
        return index;
    }
    searchProductIndex(offerArr,orderId){
        var orderVar=offerArr;
        var obj=orderVar.find(function (x) {
                return x.productid==orderId;
            }

        );
        var index=-1;
        if (obj!=undefined)
            index=orderVar.indexOf(obj);
        return index;
    }

	handleAddToCart = (e, orderId, catId,typeId,product) => {
    console.log(product);
    AddToCart(orderId,catId,typeId,product);

    this.setState({displayBasket:true});

  }

        handleRemoveFromCart = (e, orderId, catId,typeId,product) => {
	    console.log(orderId,catId,typeId,product);

      this.setState({displayBasket:true});

            RemoveFromCart(orderId, catId,typeId,product);
 
  }

  render(){


    let basket;
    let fullbasket=< FullBasket handleAddToCart={this.handleAddToCart}/>;
    let smallbasket;
    if (this.state.displayBasket) {
      basket = <Basket getTotalPrice={this.getTotalPrice} handleRemoveFromCart={this.handleRemoveFromCart} handleAddToCart={this.handleAddToCart} showBasket={this.state.displayBasket} getOfferName={this.getOfferName} getCategoryName={this.getCategoryName} get cartItems={this.state.cartItems} funcDisplayBasket={this.funcDisplayBasket}/>;

    }

      return(
        <div className="App">
  
        
        <Header funcDisplayBasket={this.funcDisplayBasket}/>
      
       {basket}

        {/* <Collapsible/> */}
       <div class="container">
                  <Offer handleAddToCart={this.handleAddToCart}/>
                   
           </div>
        </div>
        );
      
    
  }
}


export default App;
