import React,{Component,Fragment} from 'react';
import './alertone.css';

class AlertOne extends Component{
    constructor(props){
        super(props);
        this.state={
            closeThis:'alert-outer',
            enable:true
        }




    }
    closeMe=(e)=>{

        this.setState({closeThis:'hide-alert'});

    }
    componentDidMount() {
        setTimeout(function(){
            this.setState({closeThis:'hide-alert'});
        }.bind(this),5000);
    }

    render(){


        return(



            <Fragment>
            <div className={this.state.closeThis} onClick={(e)=>this.closeMe(e)}>
                <div className={"container"}>
                     <span className={"alert-text"}>
                         {this.props.message}
               </span>
                    <span className={"alert-image"}>
                    <img src={"img/icons/delete.png"}/>
                </span>
                </div>


            </div>

            </Fragment>


        );
    }
}
export default AlertOne;