import React,{Component,Fragment} from 'react';
import {ShowDeliveryPoint} from "./services/ShowDeliveryPoint";
import {ShowDeliveryTime} from "./services/ShowDeliveryTime";
import DeliveryAddress from "./DeliveryAddress";
import M from "materialize-css";
// import "./basketicon.css";
// const proxy = require('http-proxy-middleware');


class PickUpPoint extends Component{
    constructor(props){
        super(props);
        this.state={
            points:[],
            times:[],
            point_selected:false,
            time_selected:false,
            value:'select',
            defaultSelected:null,
            pointProps:null,
            pointChangedSelected:0

        }
        this.timeSelected=this.timeSelected.bind(this);
        this.pointSelected=this.pointSelected.bind(this);
        this.pointAutoChanged=this.pointAutoChanged.bind(this);

    }
    componentDidUpdate() {
        console.log(this.props.defaultPoint);

        // let collapsible = document.querySelectorAll(".collapsible");
        //
        // M.Collapsible.init(collapsible, {});
        var elems = document.querySelectorAll('select');
       M.FormSelect.init(elems, {});
        M.AutoInit();
        // this.state.pointProps=this.props.defaultPoint;
        // console.log(this.state.pointProps);

        // if(this.state.pointProps!=null){
        //     this.setState({pointProps:this.props.defaultPoint});
        // }

    }

    componentDidMount() {
        // console.log("component did mount");
        M.AutoInit();
        // this.state.pointProps=this.props.defaultPoint;
        // console.log(this.state.pointProps);


    }

    pointAutoChanged(pointId,orderIndex,categoryIndex){
        // var i=1;
        // this.setState({pointChangedSelected:i});
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndex].category_arr[categoryIndex].point_id=pointId;
        order.list[orderIndex].category_arr[categoryIndex].address=null;
        order.list[orderIndex].category_arr[categoryIndex].area=null;
        order.list[orderIndex].category_arr[categoryIndex].region=null;
        order.list[orderIndex].category_arr[categoryIndex].city=null;
        order.list[orderIndex].category_arr[categoryIndex].user_address_id=null;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));



    }
    pointSelected(e,orderIndexf,categoryIndexf){
        console.log("point select");
        console.log(e.target.value);
        var val =e.target.value;
        var i=1;
        if(e.target.value){
            this.setState({point_selected:true});
           this.props.renderFromChild(val);
        }
        this.setState({pointChangedSelected:i});
        console.log("chanfed");
        // this.props.renderFromChild(e);
        console.log(this.state.point_selected);
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].point_id=val;
        order.list[orderIndexf].category_arr[categoryIndexf].address=-1;
        order.list[orderIndexf].category_arr[categoryIndexf].area=-1;
        order.list[orderIndexf].category_arr[categoryIndexf].region=-1;
        order.list[orderIndexf].category_arr[categoryIndexf].city=-1;
        order.list[orderIndexf].category_arr[categoryIndexf].user_address_id=-1;
        order.list[orderIndexf].category_arr[categoryIndexf].user_address_type_id=-1;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    timeSelected(e,orderIndexf,categoryIndexf){
        console.log("time select");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({time_selected:true});
        }
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].time_id=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    componentDidMount(){

        ShowDeliveryPoint().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({points:responseJson})

        });
        ShowDeliveryTime().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({times:responseJson})

        });





    }

    render(){
        const defaultPointSelect=this.state.value;
        const points=this.state.points;
        const times=this.state.times;
        // this.setState({pointProps:this.props.defaultPoint});
        // this.state.pointProps=this.props.defaultPoint;
        console.log(this.state.pointProps);
        this.state.pointProps=this.props.defaultPoint;
        if (this.state.pointChangedSelected==1){
            var pointProps=null;
        }
        else{
            var pointProps=this.state.pointProps;
            this.pointAutoChanged(this.state.pointProps,this.props.orderIndexpick,this.props.categoryIndexpick);

        }
        console.log(pointProps);


        return(
            <div className="">
                <div className="space-custom"></div>
                <div className="space-custom"></div>
                <div className="input-field col s12">

                    <select value={pointProps} onChange={(e) =>this.pointSelected(e,this.props.orderIndexpick,this.props.categoryIndexpick)}>
                    <option  selected disabled>Select From Hot Points
                    </option>
                    {points.map((point,index)=>
                        <option  key={index} value={point['delivery_point_id']}>{point['delivery_points']}</option>
                    )}

                        </select>
                    <label>Free Delivery Point</label>
                </div>
                {/*<div>{this.state.value}</div>*/}

                {/*<div className="space-custom-fullbasket"></div>*/}
                <div className="space-custom"></div>
                <div className="space-custom"></div>
                <div className="input-field col s12">
                    <select className="width" onChange={(e) =>this.timeSelected(e,this.props.orderIndexpick,this.props.categoryIndexpick)} required>
                    <option value="" disabled selected required>Select Time Range
                    </option>
                    {times.map(time=>
                        <option value={time['delivery_time_id']}>{time['delivery_times']}</option>)}

                </select>
                    <label>Select Delivery Time</label>
                </div>
            </div>
        );

    }
}
export default PickUpPoint;