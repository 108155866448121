import React, {Component} from 'react';

import {PostData} from "./services/PostData";
import {Redirect} from "react-router-dom";
import { BrowserRouter as Router, Route, Link , browserHistory} from "react-router-dom";
import "./profile.css";
import {CompletePayment} from "./services/CompletePayment";
import {SaveProfile} from "./services/SaveProfile";
import {GetProfile} from "./services/GetProfile";
class Login extends Component {
    constructor(){
        super();
        this.state={
            redirectToHome: false
        }
    this.logout=this.logout.bind(this);
        this.handleSaveProfile=this.handleSaveProfile.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    };
handleSaveProfile=()=>{
    var fullprofile={};
    SaveProfile(fullprofile).then((result) => {
        let responseJson = result;
        console.log(result);
        // console.log(responseJson['status']);
        if(responseJson['status']=="successfull"){

        }
        else{
            // sessionStorage.setItem('userData',JSON.stringify(responseJson));
            // this.props.hostory.push('/paymentmethod');
            // alert("payment not completed");

        }

        // console.log(this.state);
    });
}
componentDidMount() {

    // console.log(this.props.location.state.fbDetails);
    var profile={};
    GetProfile(profile).then((result) => {
        let responseJson = result;
        console.log(result);
        // console.log(responseJson['status']);
        if(responseJson['status']=="successfull"){

        }
        else{
            // sessionStorage.setItem('userData',JSON.stringify(responseJson));
            // this.props.hostory.push('/paymentmethod');
            // alert("payment not completed");

        }

        // console.log(this.state);
    });
}

    logout(){
        // localStorage.setItem('session',null);
        sessionStorage.setItem('firstLoad',null);
        window.localStorage.removeItem("session");
        window.localStorage.removeItem("cartItems");
         sessionStorage.setItem('token',null);
         sessionStorage.setItem('loggedin',"false");
         var sessiondata="false"
        localStorage.setItem('session',JSON.stringify(sessiondata));
     sessionStorage.clear();
    console.log(sessionStorage.getItem('token'));
    this.setState({redirectToHome:true});
}
    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
    }




    render() {
    if(this.state.redirectToHome){
        return (<Redirect to='/'/>);
    }
        return (

            <div>
                <div className="container">
                    <div className="details-header">
                        <div className="back-button">

                            <Link to="/"><div className="back-image"><img src="img/icons/back.png"/></div></Link>
                        </div>
                        <div className="button-text">
                            back to main
                        </div>

                        <button className="btn green accent-4 logout-btn" onClick={this.logout}>logout</button>
                    </div>

                </div>
                <div class="full-basket-container">
                    <div class="container-small-profile">
                        <div class="">

                            <div className="space-custom">

                            </div>

                    <span className="profile-heading">Account Details</span>
                            <div className="space-custom">

                            </div>

                            <hr/>


                            <div className="row">
                                <form onSubmit={this.handleSubmit} className="col s12">
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <input id="first_name"  type="text" className="validate"/>
                                            <label htmlFor="last_name">First Name</label>
                                        </div>
                                        <div className="input-field col s6">
                                            <input id="last_name" type="text" className="validate"/>
                                                <label htmlFor="last_name">Last Name</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="input-field col s12">
                                            <input id="email" type="email" className="validate"/>
                                            <label htmlFor="email">Email</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col l6 s12">
                                            <input id="height" type="email" className="validate"/>
                                            <label htmlFor="email">Age</label>
                                        </div>
                                        <div className="col l6 s12">
                                            <select className="width" >
                                                <option value="" disabled selected>Select Gender
                                                </option>

                                                    <option >Male</option>
                                                <option >Female</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col l6 s12">
                                            <input id="height" type="email" className="validate"/>
                                            <label htmlFor="email">Height</label>
                                        </div>
                                        <div className="input-field col l6 s12">
                                            <input id="height" type="email" className="validate"/>
                                            <label htmlFor="email">HWeight</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="input-field col  l6 s12">
                                            <input id="password" type="password" className="validate"/>
                                                <label htmlFor="password">Password</label>
                                        </div>
                                        <div className="input-field col l6  s12">
                                            <input id="password" type="password" className="validate"/>
                                            <label htmlFor="password">Password Again</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="input-field col  l6 s12">
                                            <input id="password" type="password" className="validate"/>
                                            <label htmlFor="password">Mobile Number</label>
                                        </div>
                                        <div className="col l6  s12">
                                            <button> send Verification Code</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col  l6 s12">
                                            <input id="password" type="password" className="validate"/>
                                            <label htmlFor="password">Verification Code</label>
                                        </div>
                                        <div className="col l6  s12">
                                            {/*<button> send Verification Code</button>*/}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col  l6 s12">
                                            <input type="submit" value="Submit" />
                                        </div>
                                        <div className="col l6  s12">
                                            {/*<button> send Verification Code</button>*/}
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
export default Login;