export function GetOfferName(offerId) {
    var items=JSON.parse(localStorage.getItem('apiData'));
    var offername;
    // return "today";

    console.log(offerId);
    for(var i=0; i<items.length; i++){

        if(items[i].offer_id==offerId){
            offername=items[i].offer_name;
            console.log(offername);
            return offername;
        }
    }
}