import React,{Component,Fragment} from 'react';

import FullBasketProduct from "./FullBasketProduct";
class FullBasketType extends Component{
    constructor(props){
        super(props);

    }

    render(){

        return(



            <Fragment>


                <div class="">



                    {this.props.typeItem.product_arr.map(productItem=>(
                        <FullBasketProduct calculateTotal={this.props.calculateTotal} handleRemoveFromCart={this.props.handleRemoveFromCart} handleAddToCart={this.props.handleAddToCart} offerId={this.props.offerId} categoryId={this.props.categoryId} typeId={this.props.typeItem.type_id} productItem={productItem}/>

                    ))}

                </div>

            </Fragment>


        );
    }
}
export default FullBasketType;