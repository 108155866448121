import {SearchOrderIndex} from "./SearchOrderIndex";
import {SearchCategoryIndex} from "./SearchCategoryIndex";
import {SearchTypeIndex} from "./SearchTypeIndex";
import {SearchProductIndex} from "./SearchProductIndex";

export function RemoveFromCart(orderId, catId,typeId,product){
    var order=JSON.parse(localStorage.getItem('cartItems'));
    var orderIndex=SearchOrderIndex(order.list,orderId);
    console.log("orderindex="+orderIndex);

    console.log(order.list[orderIndex])
    var orderElement=order.list[orderIndex];
    console.log(orderElement);
    var category_array=orderElement.category_arr;
    console.log(category_array);
    var categoryIndex=SearchCategoryIndex(category_array,catId);
    console.log("categoryindex="+categoryIndex);

    console.log("categorydup");
    var category_element=category_array[categoryIndex];
    var type_array=category_element.type_arr;
    var typeIndex=SearchTypeIndex(type_array,typeId);
    console.log("typeindex="+typeIndex);


    var type_element=type_array[typeIndex];
    var product_array=type_element.product_arr;
    console.log(product.productid);
    var product_id=product.sizepriceid;
    var product_index=SearchProductIndex(product_array,product_id);
    console.log("productindex="+product_index);
    console.log(product_index);


    var product_element=product_array[product_index];
    console.log(product_element);
    if(product_element.quantity>1){

        product_element.quantity--;
        product_element.priceQuantity=product_element.price*product_element.quantity;
    }

    else{

        var removed = product_array.splice(product_index,1);
        console.log(removed);
        // this.setState({displayBasket:true});
    }

    console.log(product_array.length);
    if(product_array.length==0){
        var typeremoved= type_array.splice(typeIndex,1);
        console.log(typeremoved);
    }
    else{
        type_element.product_arr=product_array;
        type_array[typeIndex]=type_element;
    }
    if(type_array.length==0){
        var categoryremoved=category_array.splice(categoryIndex,1);
        console.log(categoryremoved);
    }
    else{
        category_element.type_arr=type_array;
        category_array[categoryIndex]=category_element;
    }



    orderElement.category_arr=category_array;
    order.list[orderIndex]=orderElement;
    console.log(order.list[orderIndex]);


    console.log(order);

    localStorage.setItem('cartItems', JSON.stringify(order));

}