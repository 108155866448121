import React,{Component,Fragment} from 'react';
import './payment.css';
import DetailsHeader from "./details/DetailsHeader";
import FullBasketCategory from "./fullBasket/FullBasketCategory";
import {GetOfferName} from "./services/GetOfferName";
import {GetCategoryName} from "./services/GetCategoryName";
import {CompletePayment} from "./services/CompletePayment";
import {Link} from "react-router-dom";



// import 'materialize-css';
// import 'materialize-css/dist/css/materialize.min.css';
class Payment extends Component{
    constructor(props){
        super(props);
        this.state = {
            payment:false,
        };
        this.paymentMethodSelect=this.paymentMethodSelect.bind(this);
        this.handleFinalOrder=this.handleFinalOrder.bind(this);


    }
    paymentMethodSelect(e,method){

       // var selected= e.target.value;
       // console.log(selected);

       if(method=="cash") {
           console.log("inside");
           this.state.payment=true;
           this.setState({'paymnent':true});
           console.log(this.state.payment);

       }
       else{
           this.state.payment=false;
       }
       console.log(this.state.payment);

    }
    redirect=()=>{
        console.log("redirect");
        // localStorage.clear();
        this.props.history.push('/ordercompleted');
    }
    // redirect=()=>{
    //     console.log("redirect");
    //     // localStorage.clear();
    //     this.props.history.push('/paymentmethod');
    // }
    handleFinalOrder= () => {
        var payment = {};
        payment.order_id=JSON.parse(localStorage.getItem('orderId'));
        payment.method=1;
        if(this.state.payment){
            CompletePayment(payment).then((result) => {
                let responseJson = result;
                console.log(result);
                // console.log(responseJson['status']);
                if(responseJson['status']=="successfull"){
                    // sessionStorage.setItem('userData',JSON.stringify(responseJson));
                    // this.props.hostory.push('/paymentmethod');
                    localStorage.setItem('cartItemsPayment',null);
                    window.localStorage.removeItem("cartItemsPayment");
                    alert("payment successfull");
                    this.redirect();
                    //
                    // return( <Redirect to='/paymentmethod'/>);
                    // <Payment/>
                    // this.redirect();
                }
                else{
                    // sessionStorage.setItem('userData',JSON.stringify(responseJson));
                    // this.props.hostory.push('/paymentmethod');
                    alert("payment not completed");

                }

                // console.log(this.state);
            });
        }
        else{
            alert("please select payment method");
        }

        // order.token = JSON.parse(sessionStorage.getItem('token'));
        // order.total = JSON.parse(localStorage.getItem('orderTotal'));

console.log(payment);






       // console.log(this.state.payment);
       // if(this.state.payment){
       //     localStorage.clear();
       //     alert("order completed");
       //     this.props.history.push('/');
       // }
       // else{
       //     alert("please select payment method");
       // }
        // if(true){
        //     CompletePayment(payment).then((result) => {
        //         let responseJson = result;
        //         console.log(responseJson);
        //         // console.log(responseJson['status']);
        //         if(responseJson['status']=="successfull"){
        //             // sessionStorage.setItem('userData',JSON.stringify(responseJson));
        //             // this.props.hostory.push('/paymentmethod');
        //             alert("order successfull");
        //             //
        //             // return( <Redirect to='/paymentmethod'/>);
        //             // <Payment/>
        //             // this.redirect();
        //         }
        //         else{
        //             // sessionStorage.setItem('userData',JSON.stringify(responseJson));
        //             // this.props.hostory.push('/paymentmethod');
        //             alert("order not completed");
        //
        //         }
        //
        //         // console.log(this.state);
        //     });
        // }
        // else{
        //     alert("plese select address and time")
        // }
    }


  
    render(){

        var cartItems1=JSON.parse(localStorage.getItem('cartItemsPayment'));
        if(this.state.payment){
            var selected=<span className="button-image-container-tick">
                                                                <img className="button-image-tick" src="img/icons/tick.png"/>
                                                            </span>;
        }
        var total=cartItems1.finalordertotalprice;
        var cartItems=cartItems1.list;

console.log(cartItems1);
        return(
            <div>


                <div className="container">
                        <div className="details-header">
                             <span className="back-button">

                                <Link to="/"><div className="back-image"><img src="img/icons/back.png"/></div></Link>
                             </span>
                            <span className="button-text">
                              back to main
                             </span>


                        </div>

                    </div>

                <div className="full-basket-container">


                    <div className="containe">

                        <div className="">




                                        <div className="seperate-order">
                                         <div className="container">
                                             <div className="row">
                                                 <div className="col l6">
                                                     <div className="container-small-payment">
                                                         {/*<div className="space-custom"></div>*/}
                                                         <div className="payment-inside-container">
                                                             <div className="co">
                                                                 <b>Payment Method</b>
                                                                 <div className="space-medium"></div>
                                                                 <div className="font-weight-med">
                                                                     select payment method from below
                                                                 </div>
                                                                 <div className="space-medium"></div>
                                                                 <div className="space-custom"></div>

                                                                 <div className="custom-wide-button" onClick={(e) =>this.paymentMethodSelect(e,"cash")}>
                                                            <span className="button-image-container">
                                                                <img className="button-image" src="img/icons/cashondelivery.png"/>
                                                            </span>
                                                                     <span className="custom-button-text">
                                                                Cash on Delivery
                                                            </span>
                                                                     {selected}
                                                                 </div>

                                                                 <div className="space-medium"></div>
                                                                 <div className="custom-wide-button">
                                                            <span className="button-image-container">
                                                                <img className="button-image" src="img/icons/paypal.png"/>
                                                            </span>
                                                                     <span className="custom-button-text">
                                                                Paypal
                                                            </span>
                                                                 </div>
                                                                 <div className="space-medium"></div>
                                                                 <div className="custom-wide-button">
                                                            <span className="button-image-container">
                                                                <img className="button-image" src="img/icons/bkash.png"/>
                                                            </span>
                                                                     <span className="custom-button-text">
                                                                Bkash
                                                            </span>
                                                                 </div>
                                                                 <div className="space-big"></div>

                                                             </div>
                                                         </div>
                                                     </div>
                                                     <div className="seperate-order">
                                                         <div className="order-large-button space-big-pad4-top">

                                                             <button onClick={this.handleFinalOrder} className="btn green accent-4 order-large-button font-weight-600">Complete Order</button>
                                                         </div>
                                                     </div>
                                                 </div>

                                                 <div className="col l6">
                                                     <div className="container-small-payment-bill">
                                                         <div className="container-small-inside">
                                                             <div className="memo-category order-title">
                                                                 Your Order
                                                             </div>

                                                             <hr/>

                                                             {cartItems.map(cartItem=>
                                                                 <Fragment>


                                                                     {  cartItem['category_arr'].map(type=>
                                                                         <Fragment>
                                                                             <div className="space-custom"></div>
                                                                             <span className='memo-category memo-space'>{GetOfferName(cartItem['order_id'])} {GetCategoryName(cartItem['order_id'],type['category_id']) }</span>
                                                                             <div className="space-custom"></div>
                                                                             <hr/>
                                                                             { type['type_arr'].map(product=>

                                                                                 product['product_arr'].map(pdt=>
                                                                                     <Fragment>

                                                                                         <table className="memo-food">

                                                                                                 <tr>
                                                                                                     <td className="table-space-name">
                                                                                                         { pdt['productname']}
                                                                                                     </td >
                                                                                                     <td className="table-space">
                                                                                                         { pdt['size']}
                                                                                                     </td>
                                                                                                     <td className="table-space">
                                                                                                         x{ pdt['quantity']} pcs
                                                                                                     </td>
                                                                                                     <td className="table-space-price">
                                                                                                         ={ pdt['priceQuantity']}.00 BDT
                                                                                                     </td>
                                                                                                 </tr>

                                                                                         </table>

                                                                                     </Fragment>
                                                                                 )
                                                                             )

                                                                             }
                                                                         </Fragment>
                                                                     )}
                                                                 </Fragment>
                                                             )}
                                                             <div className="space-big-pad4"></div>
                                                             <hr/>
                                                             <div className="space-custom"></div>
                                                             <table className="memo-food">
                                                                 <tr>
                                                                     <td className="table-space">Delivery Charge</td> <td className="table-space memo-bottom-table">0.00 BDT</td>
                                                                 </tr>
                                                                 <tr>
                                                                     <td className="table-space">Discount</td> <td className="table-space memo-bottom-table">0.00 BDT</td>
                                                                 </tr>
                                                                 <tr className="memo-category">
                                                                     <td className="table-space">Total Price</td> <td className="table-space memo-bottom-table">{total}.00 BDT</td>
                                                                 </tr>
                                                             </table>
                                                             <div className="space-custom"></div>
                                                             <hr/>
                                                             <div className="space-pad-2"></div>
                                                             <div className="memo-food">
                                                                 Please fill up profile details & Get 5% discount
                                                             </div>
                                                             <div className="memo-category">
                                                                 <a className="h-purple">Learn more</a>
                                                             </div>
                                                             <div className="space-big"></div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>


                                        </div>




                            {/*<button onClick={this.redirectToFullBasket} className="btn green accent-4" type="submit"*/}
                            {/*        name="action">Go To Checkout*/}
                            {/*    /!*<Link to={{*!/*/}
                            {/*    /!*    pathname: '/login'*!/*/}
                            {/*    /!*}}>Full Basket</Link>*!/*/}
                            {/*</button>*/}

                        </div>

                    </div>

                </div>
            </div>
        );
    }
}
export default Payment;