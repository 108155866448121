import React,{Component} from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import {Redirect} from'react-router-dom';
import {Link} from "react-router-dom";
import './header.css';
import BasketIcon from "./BasketIcon";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
class Header extends Component{
  constructor(props){
    super(props);
    this.state={
      loggedIn:true
    }


  }
  redirectLogin(){
    console.log("loginvar");
    this.props.history.push('/login');
  }
  componentDidMount() {
    sessionStorage.setItem('loggedin','true');
  }


  render(){

    // console.log(JSON.parse(localStorage.getItem('session'))=="false");
    let login;
    // if(JSON.parse(localStorage.getItem('session'))==false){
    //   login=<li><Link class="text-color" to="/login">Login</Link ></li>;
    // // }
    // if(JSON.parse(localStorage.getItem('session'))=="false"){
    //   login=<li><Link class="text-color" to={{
    //     pathname: '/login',
    //     state: {
    //       fromHeader: true
    //     }
    //   }}>Login</Link ></li>;
    // }
    //

    console.log(JSON.parse(localStorage.getItem('sessionData')));
    if(JSON.parse(localStorage.getItem('session'))==null){

      login= <li><Link class="text-color" to={{
        pathname: '/login',
        state: {
          fromHeader: true
        }
      }}>login</Link ></li>;
    }
    else{
      var sessionName={};
      if((JSON.parse(localStorage.getItem('sessionData'))!=null)){
        // sessionName=JSON.parse(localStorage.getItem('sessionData'));
        // if(sessionName.name==undefined){
        //   sessionName.name=sessionName.profileObj.givenName;
        // }
      }
      else{
        sessionName.name="Profile";
      }

      console.log(sessionName.name);
      login= <li><Link class="text-color" to="/profile">My Profile</Link ></li>;
    }
    let basketIcon;
    basketIcon= <li onClick={(e)=>this.props.funcDisplayBasket(e,1)}>
<BasketIcon/>
    </li>;
    // console.log(sessionStorage.getItem('basketIcon'));
    if(sessionStorage.getItem('basketIcon')=='true'){
      console.log("yeppi");

    }
      // const images = [
      //       { url: "img/banner/banner.jpg" },
      //       { url: "img/banner/slider2.png" },
      //       { url: "img/banner/lighthouse.jpg" },
      //       { url: "img/banner/butterfly.jpg" },
      //   ];
    return(
     <header id="home1" className="main-header section scrollspy">

  <div class="primary-overlay">


    <div class="navbar-fixed">
      <nav class="transparent" id="transparent-navbar-main">
        <div class="container">
          <div class="nav-wrapper  background-overlay">

            <div class="main-logo-item">
              <img src="img/main-logo.png" class="brand-logo"alt=""/>
            </div>
          
            <a href="#" data-activates="mobile-nav" class="button-collapse">
              <i class="material-icons">menu</i>
            </a>
           <div class="right hide-on-med-and-down">
            <ul class="section table-of-contents">
              <li>
                <a class="text-color" href="#home1">Home</a>
              </li>
              <li>
                <a class="text-color" href="#main-meal">Daily Meal</a>
              </li>
              <li>
                <a class="text-color" href="#search">Search</a>
              </li>
              <li>
                <a class="text-color" href="#big-card-carousel">Top Trending</a>
              </li>
              {/*<li>*/}
              {/*  <Link to="/login">Login</Link >*/}
              {/*</li>*/}
              <li>
                {login}
              </li>
              {/*<li>*/}
              {/*  <a onClick={this.props.funcDisplayBasket} class="text-color">Basket</a>*/}
              {/*</li>*/}

                {basketIcon}


            </ul>
           </div>
          </div>
        </div>
      </nav>
    </div>
   
    <ul class="side-nav" id="mobile-nav">
      <h4 class="blue-grey darken-3 center">LefuFoo</h4>
      <li>
        <a class="divider"></a>
      </li>
      <li>
        <a href="#home">Home</a>
      </li>
      <li>
        <a href="#main-meal">Daily Meal</a>
      </li>
      <li>
        <a href="#search">Search</a>
      </li>
      <li>
        <a href="#contact">Contact</a>
      </li>
    </ul>





  </div>

</header>

      );
  }
}


export default Header;