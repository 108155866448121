import React,{Component} from 'react';
import { Link } from "react-router-dom";
import Basket from "../Basket";
import BasketIcon from "../BasketIcon";
import './detailsheader.css';
class DetailsHeader extends Component{
    constructor(props){
        super(props);


    }
    componentDidMount(){


        window.scrollTo(0, 0)
    }
  render(){
      let basketIcon;
      basketIcon= <span className="details-basket-icon" onClick={(e)=>this.props.funcDisplayBasket(e,1)}>
          <BasketIcon/>
      </span>;
        let basket;
        console.log(this.props.detailsDisplayBasket);
        if (this.props.detailsDisplayBasket){
            basket=<Basket funcDisplayBasket={this.props.funcDisplayBasket} handleRemoveFromCart={this.props.handleRemoveFromCart} handleAddToCart={this.props.handleAddToCart}/>;
        }
    return(
          <div class="container">
            <div class="details-header pinned-top">
              <span class="back-button">
                    

                  <Link to={{
                      pathname: '/',
                      state: {
                          fromDetails: true
                      }
                  }}><div className="back-image"><img src="img/icons/back.png"/></div></Link>
                    </span>
                    <span class="button-text">
                      back to main
                    </span>
                  {basketIcon}
                {basket}

              </div>
              
           </div>

      );
  }
}


export default DetailsHeader;