import React,{Component} from 'react';
import DetailsHeader from './DetailsHeader';
import DetailsMain from './DetailsMain';
import Foods from '../Foods';
import {AddToCart} from "../services/AddToCart";
import {RemoveFromCart} from "../services/RemoveFromCart";
import {Redirect} from "react-router-dom";
class Details extends Component{
	  constructor(props){
    super(props);
   this.state={
       detailsDisplayBasket:false
   }
   this.displayBasketInDetails=this.displayBasketInDetails.bind(this);
   this.handleAddToCart=this.handleAddToCart.bind(this);
   this.handleRemoveFromCart=this.handleRemoveFromCart.bind(this);
   this.funcDisplayBasket=this.funcDisplayBasket.bind(this);

  }
    displayBasketInDetails=(offerId,CategoryId,typeId,product,clicked)=>{
	      this.setState({detailsDisplayBasket:true});
	      console.log(this.state.detailsDisplayBasket);
        AddToCart(offerId,CategoryId,typeId,product);
	      // if (clicked==true){
          //     this.setState({detailsDisplayBasket:true});
          // }
	      // else{
          //     this.setState({detailsDisplayBasket:false});
          // }
}
    handleAddToCart = (e, orderId, catId,typeId,product) => {
        this.setState({detailsDisplayBasket:true});
        AddToCart(orderId,catId,typeId,product);
    }
    handleRemoveFromCart=(e,orderId, catId,typeId,product)=>{
        this.setState({detailsDisplayBasket:true});
        RemoveFromCart(orderId, catId,typeId,product);
}
    funcDisplayBasket (e,i){
        console.log('wow');
        console.log(i);
        if(i==0){
            this.setState({detailsDisplayBasket:false});
        }
        else{
            this.setState({detailsDisplayBasket:true});
        }


    }
  componentDidMount(){
    //     console.log(this.props.location.state);
    // const {foods}=this.props.location.state;
    //     const {paramofferid}=this.props.location.state;
    //   const {paramcategoryid}=this.props.location.state;
    //   const {paramtypeid}=this.props.location.state;
    //     console.log(paramofferid);
      window.scrollTo(0, 0);

  }
  
  render(){
      if ((this.props.location.state)==undefined){
          return (<Redirect to='/'/>);

      }
  	     var {foods} =this.props.location.state;
      var {paramofferid}=this.props.location.state;
      var {paramcategoryid}=this.props.location.state;
      var {paramtypeid}=this.props.location.state;

    return(
      <div>
          <DetailsHeader funcDisplayBasket={this.funcDisplayBasket} handleRemoveFromCart={this.handleRemoveFromCart}handleAddToCart={this.handleAddToCart}detailsDisplayBasket={this.state.detailsDisplayBasket}/>
          <DetailsMain displayBasketInDetails={this.displayBasketInDetails} offerid={paramofferid} categoryid={paramcategoryid} typeid={paramtypeid} foods={foods}/>

      </div>
      );
  }
}


export default Details;