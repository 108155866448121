export function GetCategoryName(offerId,categoryId) {
    var items=JSON.parse(localStorage.getItem('apiData'));
    // return "new";
    console.log(categoryId);

    console.log(categoryId);
    console.log(offerId);
    for(var i=0; i<items.length; i++){



        if(items[i].offer_id==offerId){
            console.log(items.length);
            for(var j=0; j<20; j++){
                if(items[i].category[j]==undefined){
                    break;
                }

                if(items[i].category[j].category_id==categoryId) {
                    var categoryname;
                    categoryname=items[i].category[j].category_name;
                    console.log(categoryname);
                    return categoryname;
                }
            }
        }


    }
}