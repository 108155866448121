import React,{Component,Fragment} from 'react';
// import './BasketCart.css'
import '../basket/basketproduct.css';
class FullBasketProduct extends Component{
    constructor(props){
        super(props);
        this.calculateItemTotal=this.calculateItemTotal.bind(this);

    }
    calculateItemTotal(price,quantity){
        return price*quantity;
    }

    render(){


        return(



            <Fragment>


                <div class="product-space">




                    <table>
                        <tr className='table-row-tr'>
                            <td className='image-table-td' >
                                <img className='basket-image' src={this.props.productItem.imagepath}/>
                            </td>

                            <td >
                                <div className='cart-quantity-name padding-bottom-1'>{this.props.productItem.productname} ({this.props.productItem.size})
                                </div>
                                <div className='cart-quantity-name'>
                                    <span className={"add-remove-icon"} onClick={(e) => this.props.handleRemoveFromCart(e, this.props.offerId, this.props.categoryId,this.props.typeId, this.props.productItem)}>
                                            <img src={'img/icons/substract.png'}/>
                                        </span>
                                    <span className='item-qty font-1rem'>{this.props.productItem.quantity}</span>
                                    <span className={"add-remove-icon"}  onClick={(e) => this.props.handleAddToCart(e,this.props.offerId, this.props.categoryId,this.props.typeId, this.props.productItem)}>
                                        <img src={'img/icons/add.png'}/>
                                    </span>
                                </div>
                            </td>
                            <td className='cart-quantity-name font-1rem'>
                                { this.props.productItem.priceQuantity} BDT
                            </td>

                        </tr>
                    </table>

                </div>

            </Fragment>


        );
    }
}
export default FullBasketProduct;