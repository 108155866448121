import React,{Component,Fragment} from 'react';

import './starrating.css';
import {PostData} from "../services/PostData";
import {CanReview} from "../services/CanReview";
import {GiveRating} from "../services/GiveRating";


class StarRating extends Component{
    constructor(props){
        super(props);
        this.state={
            canreview:false
        }
    }
    setRating(event){
        console.log(event.target.value);
        var token=JSON.parse(localStorage.getItem('session'));
        var productid=this.props.productid;
        var ratingDetails={};
        ratingDetails.token=token;
        ratingDetails.productid=productid;
        ratingDetails.ratingvalue=event.target.value;
        console.log(ratingDetails);
        GiveRating(ratingDetails).then((result) => {
            let responseJson = result;
            console.log(responseJson);

            if(responseJson.status=="successfull"){
                alert("rating stored successfully");

            }

            console.log(this.state);
        });
        // alert("input taken");
    }
    componentDidMount() {
      var token=JSON.parse(localStorage.getItem('session'));
        CanReview(token).then((result) => {
            let responseJson = result;
            console.log(responseJson);

            if(responseJson.status=="successfull"){
                this.setState({'canreview':true});

            }

            console.log(this.state);
        });
    }

    render(){
       if(this.state.canreview){
           return(


               <div className="row half-width">
                   <div onChange={this.setRating.bind(this)} className="rate">
                       <input type="radio" id="star5" name="rate" value="5"/>
                       <label htmlFor="star5" title="text">5 stars</label>
                       <input type="radio" id="star4" name="rate" value="4"/>
                       <label htmlFor="star4" title="text">4 stars</label>
                       <input type="radio" id="star3" name="rate" value="3"/>
                       <label htmlFor="star3" title="text">3 stars</label>
                       <input type="radio" id="star2" name="rate" value="2"/>
                       <label htmlFor="star2" title="text">2 stars</label>
                       <input type="radio" id="star1" name="rate" value="1"/>
                       <label htmlFor="star1" title="text">1 star</label>
                   </div>
               </div>



           );
       }
       else{
           return(<div> </div>);
       }
    }
}
export default StarRating;