import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Details from './details/Details';
import Login from './Login';
import LoginVerify from './LoginVerify';
import Profile from'./Profile';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Route, Link , browserHistory} from "react-router-dom";
import FullBasket from "./FullBasket";
import Payment from "./Payment";
import OrderCompleted from "./OrderCompleted";

ReactDOM.render(
<Router>
	<Route path="/" exact component={App}/>
		<Route path="/details" component={Details}/>
	<Route path="/basket" component={FullBasket}/>
	<Route path="/login" component={Login}/>
	<Route path="/profile" component={Profile}/>
	<Route path="/loginverify" component={LoginVerify}/>
	<Route path="/paymentmethod" component={Payment}/>
	<Route path="/ordercompleted" component={OrderCompleted}/>
</Router>
, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
