import React,{Component} from 'react';
import './detailsmain.css';
import Chef from './Chef'
import Ingredients from './Ingredients'
import {PostData} from "../services/PostData";
import {AddToCart} from "../services/AddToCart";
import Comments from "./Comments";
import StarRating from "./StarRating";

class DetailsMain extends Component{
  constructor(props){
    super(props);
    this.state = {
        changeRate:false,
    date: new Date().toLocaleString()
  };
    this.changeToInput=this.changeToInput.bind(this);
  }
  changeToInput(){
      this.setState({changeRate:true});
  }
  render(){
      var rating=this.props.foods.rating;
      console.log(typeof rating);
      var i;
      var html="";
      for(i=0; i<rating; i++){
          html += "<i class='material-icons'>star</i>";
          html+="";
      }
      var rating= rating=<div dangerouslySetInnerHTML={{__html: html}}/>;
      if(this.state.changeRate){
          rating=<StarRating productid={this.props.foods.productid}/>;
      }

    return(
   

   <div class="container">
            <div class="row">
                <div class="col l5 s12">
                    <div class="main-image-container">
                            <img src={this.props.foods.bigimagepath} class="responsive-img" alt=""/>
                    </div>
                  
                </div>
                <div class="col l7 s12">
                    <div class="main-container-content">
                          
                            <div class="main-details-content">
                                    <div class="main-tag">
                                            <span class="new badge green accent-4">4</span>
                                            <span class="new badge red accent-4">4</span>
                                          </div>
                                          <div class="date-time">
                                              Today,{this.state.date}
                                          </div>
                                          <h4>{this.props.foods.productname}</h4>
                                          <div onClick={this.changeToInput} class="rate-star">

                                              {rating}

                                              </div>

                                          <div class="main-content-side">
                                           
                                                <p >Baked with vegetables</p>
                                              </div>
                                          <div class="price">
                                            <b>{this.props.foods.price}  BDT</b>
                                          </div>
                                          <div class="button-to-cart">
                                                <button onClick={ ()=>this.props.displayBasketInDetails(this.props.offerid,this.props.categoryid,this.props.typeid,this.props.foods,true)}  class="waves-light btn green accent-4 custom-cart">add to cart</button>
                                          </div>

                            </div>
                            
                                    <div class="  heading-2 " id="heading-2-id">
                                       
                                            <ul class=" table-of-contents">
                                                
                                                <li><a href="#details">Details</a></li>
                                                <li><a href="#ingredients">Ingredients</a></li>
                                                
                                                <li><a href="#suggestion">Suggestion</a></li>
                                                <li><a href="#comments">Comments</a></li>
                                                <li><a href="#aboutchef">About Chef </a></li>
                                              </ul>
                                      
                                        </div>

                               
                                    <div id="details" className="section scrollspy">
                                            <div class="details-heading">
                                                <h4>Details</h4>
                                            </div>
                                            <div class="details-subheading">
                                                <h6 class="">Nutrition Facts Of This Food</h6>
                                            </div>
                                           <div class="details-details">
                                              <p class="">{this.props.foods.longdetails}</p>
                                              
                                           </div>
                                       
                                          </div>
<Ingredients ingredient={this.props.foods.ingredients}></Ingredients>
                       
                      
                                {/*<div id="suggestion" class="section scrollspy">*/}
                                {/*  <div class="suggestion-title">*/}
                                {/*    <h4>Desserts</h4>*/}
                                {/*  </div>*/}
                                {/*  <div class="suggestion-subtitle">*/}
                                {/*    <p>add with this item</p>*/}
                                {/*  </div>*/}
                                {/*  <div class="suggestion-content">*/}
                                {/*    <div class="row">*/}
                                {/*      <div class="col l6">*/}
                                {/*        */}
                                {/*        <div class="card horizontal">*/}
                                {/*          <div class="card-image">*/}
                                {/*            <img src="https://lorempixel.com/100/190/nature/6"/>*/}
                                {/*          </div>*/}
                                {/*          <div class="card-stacked">*/}
                                {/*            <div class="card-content">*/}
                                {/*              <p>French Cheese Burger</p>*/}
                                {/*            </div>*/}
                                {/*            <div class="card-action">*/}
                                {/*              <a href="#">add this itemk</a>*/}
                                {/*            </div>*/}
                                {/*          </div>*/}
                                {/*        </div>*/}
                                {/*      </div>*/}

                                {/*      <div class="col l6">*/}
                                {/*        */}
                                {/*        <div class="card horizontal">*/}
                                {/*          <div class="card-image">*/}
                                {/*            <img src="https://lorempixel.com/100/190/nature/6"/>*/}
                                {/*          </div>*/}
                                {/*          <div class="card-stacked">*/}
                                {/*            <div class="card-content">*/}
                                {/*              <p>French Cheese Burger</p>*/}
                                {/*            </div>*/}
                                {/*            <div class="card-action">*/}
                                {/*              <a href="#">add this itemk</a>*/}
                                {/*            </div>*/}
                                {/*          </div>*/}
                                {/*        </div>*/}
                                {/*      </div>*/}
                                {/*    </div>*/}
                                {/*  </div>*/}
                                {/*</div>*/}

                               

                    <Comments productid={this.props.foods.productid} comments={this.props.foods.reviews}/>
                
          
          <Chef chefname={this.props.foods.chefname} chefimage={this.props.foods.chefimage} chefdetails={this.props.foods.chefdetails}/>

                  
                                    
                                   
                    </div>
                </div>
            </div>
        </div>
      
         
     
      );
  }
}


export default DetailsMain;
