export function VerifyOtp( userData) {
    let as=userData.otp;
    console.log(as);
    let BaseURL = 'lefufooold/api/verifyotp?otp='+as+'';

    // console.log(BaseURL);
    return new Promise((resolve, reject) =>{
        fetch(BaseURL,  {
            method: 'POST',


        }, {mode: 'cors'})
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
                console.log(res);

            })
            .catch((error) => {
                reject(error);
            });
    });
}