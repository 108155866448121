import React,{Component,Fragment} from 'react';
import Foods from './Foods';
class Type extends Component{
	constructor(props){
		super(props);
	}
  render(){
    return(
  			
             
         <Fragment>
              		
 					
						<div class="row">
					{this.props.types.product.map(item =>(
          
					<Foods typeName={this.props.types.type_name} typeId={this.props.typeId} categoryName={this.props.categoryName} categoryId={this.props.categoryId} offerName={this.props.offerName} offerId={this.props.offerId} handleAddToCart={this.props.handleAddToCart} types={item}> </Foods>

                   ))}
 					</div>

           </Fragment>
           
            
      );
  }
}
export default Type;