import React,{Component,Fragment} from 'react';
import DetailsMain from './DetailsMain';
import './ingredients.css';
class Ingredients extends Component{
	constructor(props){
		super(props);
	}
  render(){
    return(
  			
             
              <div id="ingredients" class="section scrollspy" >
                                    <div class="ingredients-heading">
                                      <h4>
                                        Ingredients
                                      </h4>
                                    </div>
                                    <div class="ingredients-subtitle">
                                      <p class="">
                                        Uncheck to remove item
                                      </p>
                                    </div>
                                    <div class="main-ingredient">
                                   <div class="row">
                                   <form action="">
                                          {this.props.ingredient.map(item =>(
          
           <div class="col l4"><img class="ingr-img" src={item['ingredient_image']}/>   <p>
      <label class="ingr-name">
        <input type="checkbox" checked="checked" />
        <span >{item['ingredient_name']}</span>
      </label>
    </p></div>

                   ))}
                            </form>           
                                     </div>
                                    </div>
                                </div>

           
            
      );
  }
}
export default Ingredients;