// headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
export function CompletePayment(userData) {

    console.log(userData);
    let BaseURL = 'lefufooold/api/payment';

    //let BaseURL = 'http://localhost/PHP-Slim-Restful/api/';
    return new Promise((resolve, reject) =>{
        fetch(BaseURL,  {
            method: 'POST',
            body: JSON.stringify(userData),


        }, {mode: 'cors'})
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
                console.log(res);

            })
            .catch((error) => {
                reject(error);
                console.log(error);
            });
    });
}