import React,{Component,Fragment} from 'react';
import Type from './Type';
import './category.css';
class Category extends Component{
	constructor(props){
		super(props);
	
    
	 
		
	}
  render(){
  
	
    return(
  			
           
           
              <Fragment>
				  <span className={"font-1-2rem"}>{this.props.categories.category_name}</span>

 					<div class="">
					
					{this.props.categories.type.map(item =>(
							 
					<Type typeId={item.type_id} categoryName={this.props.categoryName} categoryId={this.props.categoryId} offerName={this.props.offerName} offerId={this.props.offerId} handleAddToCart={this.props.handleAddToCart} types={item}> </Type>

                   ))}
					 
 					</div>

</Fragment>
                        
           
      );
  }
}
export default Category;