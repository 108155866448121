import React,{Component,Fragment} from 'react';
import './details/basket.css';
import {Link, Redirect} from "react-router-dom";

import BasketCategory from "./basket/BasketCategory";


class Basket extends Component{
    constructor(props){
		super(props);
        this.state = {
            cart: [],
            redirectToBasket:false,
            redirectToLogin:false,
            clickOutside:'enable-basket',
            showBasket:true,
            totalPriceState:0,
            hideCart:'show-empty-cart',
            hide:false,
            totalprice:0,
            totalitems:0,
        };
        // console.log(this.props.displayBasket);
this.removeItemQuantity=this.removeItemQuantity.bind(this);
this.redirectToFullBasket=this.redirectToFullBasket.bind(this);
this.disableBasketView=this.disableBasketView.bind(this);
this.calculateTotal=this.calculateTotal.bind(this);
        // this.getTotalPrice=this.getTotalPrice.bind(this);
  }
  removeItemQuantity(){
        console.log('removeIteQ');
}

  disableBasketView(){
        this.setState({showBasket:!this.state.showBasket});
      console.log('inside function');

      // var disableBasket='disable-basket';
  }
  componentDidUpdate() {
        if(this.state.hide){
            setTimeout(function(){
                this.setState({hideCart:'hide-empty-cart'});
            }.bind(this),5000);
        }
  }


    calculateTotal=()=>{
      var cartItems1=JSON.parse(localStorage.getItem('cartItems'));
      // console.log(cartItems1);

      if(cartItems1!=undefined){
          var preorder=cartItems1.list;
          var totalItems=0;
          var total=0;
          var i;
          var j;
          var k;
          var l;
          for(i in preorder){

              for (j in preorder[i].category_arr){

                  for(k in preorder[i].category_arr[j].type_arr){
                      var  ordertotal=0;
                      var vproductarray=preorder[i].category_arr[j].type_arr[k].product_arr;
                      for(l in vproductarray){
                          ordertotal+=vproductarray[l].priceQuantity;

                          console.log(ordertotal);
                          totalItems+=vproductarray[l].quantity;
                          // console.log(totalItems);
                      }

                      preorder[i].category_arr[j].categorytotal=ordertotal;
                      total+=ordertotal;
                  }

              }

          }


          var order={};
          order.list=preorder;
          order.finalordertotalprice=total;
          order.totalitems=totalItems;
          this.state.totalitems=totalItems;
          this.state.totalprice=total;

          localStorage.setItem('cartItems', JSON.stringify(order));

          // console.log(JSON.parse(localStorage.getItem('cartItems')))
      }


  }


    removeItemQuantity(){
        // alert("removed");
    }

    redirectToFullBasket(){

        // console.log('ameazing');
        // console.log(JSON.parse(localStorage.getItem('session')));
        if((JSON.parse(localStorage.getItem('session'))==null) || (JSON.parse(localStorage.getItem('session'))=='false')){
            this.setState({
                redirectToLogin: true
            });
            // console.log("true");
        }
        else{
            this.setState({
                redirectToBasket: true
            })

        }
        // this.renderRedirect();

    }
    renderRedirect = () => {

        if (this.state.redirectToLogin) {
            return( <Redirect to={{
                pathname: '/login',
                state: { fromHeader: 'true' }
            }} />);
            // console.log("yes");
        }

        else if (this.state.redirectToBasket){
            return (<Redirect to='/basket'/>);
            // console.log("no");

        }
    }
  render(){

     
      this.calculateTotal();
      var cartItems1=JSON.parse(localStorage.getItem('cartItems'));

     if(cartItems1!=null){
         // var total=cartItems1.finalordertotalprice;
         var cartItems=cartItems1.list;
         // var totalitems=cartItems1.totalitems;
     }
     else{
         var cartItems=[];
         // total=0;
        // this.setState({hide:true});
        //  this.state.hide=true;
     }




     //  console.log(cartItems1);
     //  console.log(total);
     // console.log(typeof cartItems);


if (this.state.totalprice==0 ){
    return (<div className={this.state.hideCart}>
            {/*<AlertOne/>*/}
        <div className="basket-container container responsive-basket">

            <div className="card  darken-1 card-padding" onClick={(e)=>this.props.funcDisplayBasket(e,0)}>
                {/*<span className="bag-name">Empty Bag</span>*/}
                {/*<span className="close-button bag-name"><button onClick={(e)=>this.props.funcDisplayBasket(e,0)}>x</button></span>*/}
                {/*<div className="empty-cart-image-container">*/}
                {/*    <img className="empty-cart-image" src="img/icons/emptycart.png"/>*/}

                {/*</div>*/}
                {/*<span>Your bag is empty start adding meals</span>*/}
                <span className="button-image-container">
                            <img className="small-button-image" src="img/icons/cross.png"/>
                        </span>

                <span className={"basket-heading"}>
                            Your Bag
                        </span>
                <span>
                                {this.state.totalitems} items
                            </span>

                <div className="empty-cart-image-container">
                    <img className="empty-cart-image" src="img/icons/emptycart.png"/>

                </div>
                <span>Your bag is empty start adding meals</span>


            </div>
        </div>



    </div>);
}

      // let disableBasket = this.state.showBasket ? "enable-basket" : "disable-basket";
    return(
        <div class="containe">
            {/*<div className="enable-basket">*/}
                <div className="basket-container container responsive-basket">
                    {/*{this.props.handleAddToCart}*/}
                    <div className="card darken-1 card-padding">
                        <div onClick={(e)=>this.props.funcDisplayBasket(e,0)} className={"basket-head"}>

                        <span className="button-image-container">
                            <img className="small-button-image" src="img/icons/cross.png"/>
                        </span>

                        <span className={"basket-heading"}>
                            Your Bag
                        </span>
                            <span>
                                {this.state.totalitems} items
                            </span>

                     </div>
                       


                        {this.renderRedirect()}
                        <div className='containe'><div className='overflow'>
                            {cartItems.map(cartItem =>(
                                <Fragment>


                                    {cartItem.category_arr.map(categoryItem =>(
                                        <BasketCategory calculateTotal={this.calculateTotal} handleRemoveFromCart={this.props.handleRemoveFromCart} handleAddToCart={this.props.handleAddToCart} offerId={cartItem.order_id} getCategoryName={this.props.getCategoryName} getOfferName={this.props.getOfferName} categoryItem={categoryItem} />


                                    ))}

                                </Fragment>
                            ))}
                            </div>
                            <table className='additional-info-cart'>
                                <tr>
                                    <td className='addition-data'>delivery</td>
                                    <td className='addition-data addition-data-right'>free</td>
                                </tr>
                                <tr>
                                    <td className='addition-data'>VAT</td>
                                    <td className='addition-data-right addition-data'>Free</td>
                                </tr>
                                <tr>
                                    <td className='addition-data'>Total</td>
                                    <td className='addition-data-right addition-data'>{this.state.totalprice} BDT</td>
                                </tr>
                            </table>
                        </div>
                        <button onClick={this.redirectToFullBasket} className="btn green accent-4" type="submit"
                                name="action"><span className="font-weight-600">Go To Checkout</span>

                        </button>
                    </div>
                </div>


            <div className="show-on-small red "  onClick={this.redirectToFullBasket}>
                 <div class="container">
                     <table>
                         <tr>
                             <td><span className="item-small-qty">{this.state.totalitems}</span><span>items</span></td>
                             <td> Go To Checkout</td>
                             <td>Total Price {this.state.totalprice} BDT</td>
                         </tr>
                     </table>
                 </div>
            </div>

          </div>      
         
      );
  }
}
export default Basket;
