import React, {Component} from 'react';
import './login.css';
import {PostData} from "./services/PostData";
import {Redirect} from "react-router-dom";
import { BrowserRouter as Router, Route, Link , browserHistory} from "react-router-dom";
import {VerifyOtp} from "./services/VerifyOtp";
import DetailsHeader from "./details/DetailsHeader";
class LoginVerify extends Component {
    constructor(){
        super();
        this.state={
            otp:'',
            redirectToBasket:false
        }
        this.otpVerify=this.otpVerify.bind(this);
        this.onChangeInput=this.onChangeInput.bind(this);

    };


otpVerify(e){
    e.preventDefault();
    console.log(this.state);
    VerifyOtp(this.state).then((result) => {
        let responseJson = result;
        if(responseJson.status=="successfull"){
            sessionStorage.setItem('token',JSON.stringify(responseJson.token));
            sessionStorage.setItem('loggedin','true');
            // console.log(sessionStorage.getItem('loggedin'));
            localStorage.setItem('session',JSON.stringify(responseJson.token));
            // this.props.history.push(`/basket`);
            console.log(this.props.location.state.fromHeader);
            this.setState({redirectToBasket: true});
            // if(this.props.location.state.fromHeader){
            //     return (<Redirect to='/profile'/>);
            // }
            // else {
            //     return (<Redirect to='/basket'/>);
            // }
        }
        else{
            alert("error");
        }


    });
}

    onChangeInput(e){
        this.setState({[e.target.name]:e.target.value});

    }

    render() {
        if ((this.state.redirectToBasket) && (this.props.location.state.fromHeader=='true')){

               return (<Redirect to='/profile'/>);
           }
            if ((this.state.redirectToBasket) && (this.props.location.state.fromHeader=='false')){

               return (<Redirect to='/basket'/>);
           }
            // this.props.history.push(`/loginverify`);

        const points=this.state.points;
        const times=this.state.times;
        return (
            <div>
                <div className="container">
                    <div className="details-header">
                        <div className="back-button">

                            <Link to="/"><div className="back-image"><img src="img/icons/back.png"/></div></Link>
                        </div>
                        <div className="button-text">
                            back to main
                        </div>


                    </div>

                </div>
              <div class="full-basket-container-login">
                  <div class="container-small-of-login">
                      <div class="container1">
                          <div className="space-custom"></div>

                          <span className="login-heading">Verify OTP</span>
                          <div className="space-custom"></div>
                          <div className="text-one-rem">enter 4 digit code sent to your mobile</div>
                          <div className="space-big"></div>
                                  <form onSubmit={this.otpVerify}>
                                      <div className="input-field">
                                          <input className="login-mobile-input validate" type="text" name="otp" onChange={this.onChangeInput}/>
                                              <label htmlFor="last_name">Otp Code</label>
                                          <button className="btn green accent-4 mobile-input-button" type="submit"><span className="font-weight-600">login</span></button>
                                      </div>

                                      {/*<input className="login-mobile-input" type="text" name="otp" onChange={this.onChangeInput}/>*/}
                                      {/*<button className="btn green accent-4 mobile-input-button" type="submit"><span className="font-weight-600">login</span></button>*/}
                                  </form>



                      </div>
                      <div className="space-big"></div>
                      {/*<div className="space-big"></div>*/}
                      {/*<div className="space-big"></div>*/}
                  </div>

              </div>
            </div>
        );
    }

}
export default LoginVerify;