import React,{Component,Fragment} from 'react';
import DetailsMain from './DetailsMain'
class Chef extends Component{
	constructor(props){
		super(props);
		
	}
  render(){
  	
    return(
  			
             
                <div id="aboutchef" class="section scrollspy">
                       <div class="about-chef-heading">
                            <h4>{this.props.chefname}</h4>
                       </div>
                        <div class="about-chef-subtitle">
                            <p>Internationally recognized cook</p>
                        </div>
                        <div class="chef-image">
                            <img src={this.props.chefimage} class="responsive-img" alt=""/>
                        </div>
                              <p class="chef-details">{this.props.chefdetails}</p>
                             

                </div>
                                    


                        
           
      );
  }
}
export default Chef;