export function SearchOrderIndex(offerArr,orderId){
    var orderVar=offerArr;
    console.log(typeof offerArr.list);
    var obj=orderVar.find(function (x) {
            console.log(x);
            return x.order_id==orderId;
        }

    );
    var index=-1;
    if (obj!=undefined)
        index=orderVar.indexOf(obj);
    return index;
}