import React,{Component,Fragment} from 'react';
import "./basketicon.css";
// const proxy = require('http-proxy-middleware');


class BasketIcon extends Component{
    constructor(props){

        super(props);
        this.state={
            totalprice:0,
            totalitems:0,
        }
        this.calculateTotal=this.calculateTotal.bind(this);
    }
    calculateTotal=()=>{
        var cartItems1=JSON.parse(localStorage.getItem('cartItems'));
        console.log(cartItems1);

        if(cartItems1!=null){
            var preorder=cartItems1.list;
            var totalItems=0;
            var total=0;
            var i;
            var j;
            var k;
            var l;
            for(i in preorder){

                for (j in preorder[i].category_arr){

                    for(k in preorder[i].category_arr[j].type_arr){
                        var  ordertotal=0;
                        var vproductarray=preorder[i].category_arr[j].type_arr[k].product_arr;
                        for(l in vproductarray){
                            ordertotal+=vproductarray[l].priceQuantity;

                            // console.log(ordertotal);
                            totalItems+=vproductarray[l].quantity;
                            // console.log(totalItems);
                        }

                        preorder[i].category_arr[j].categorytotal=ordertotal;
                        total+=ordertotal;
                    }

                }

            }
    // this.setState({totalprice:total});
    //         this.setState({totalitems:totalItems});
            this.state.totalitems=totalItems;
            this.state.totalprice=total;

            // var order={};
            // order.list=preorder;
            // order.finalordertotalprice=total;
            // order.totalitems=totalItems;


            // localStorage.setItem('cartItems', JSON.stringify(order));
            //
            // console.log(JSON.parse(localStorage.getItem('cartItems')))
        }


    }

    render(){

        this.calculateTotal();
       return(
           <Fragment>
               <span>
                   <img  className="top-cart-icon" src="img/icons/cart.png"/>
               </span>
               {/*<span>1</span>*/}
        {/*       <span ><i className="material-icons">*/}
        {/*  add_shopping_cart*/}
        {/*</i></span> < span*/}
        {/*       className = 'cart-item-number' > </span>*/}
            <span className={"item-count"}>
                {this.state.totalitems}
                {/*<div>*/}
                {/*    */}
                {/*</div>*/}
                {/*<div>*/}
                {/*    {this.state.totalprice} taka*/}
                {/*</div>*/}
            </span>
         </Fragment>
       );

    }
}
export default BasketIcon;