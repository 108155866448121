import React, {Component, Fragment} from 'react';

import FullBasketType from "./FullBasketType";
import "./fullbasketcategory.css";
import DeliveryAddress from "../DeliveryAddress";
import PickUpPoint from "../PickUpPoint";


import {GetOfferName} from "../services/GetOfferName";
import {GetCategoryName} from "../services/GetCategoryName";
import {GetOfferDate} from "../services/GetOfferDate";
class FullBasketCategory extends Component{
    constructor(props){
        super(props);
        this.state={
            userAddress:[],
            size:'point',
            point_selected:true,
            time_selected:true,
            showAddressOption:false,
            changeName:'select a point below to pick from us or',
            changeImage:'img/icons/paid.png',
            clickedOutside:false
        }
        this.handleTypeChange=this.handleTypeChange.bind(this);
        this.clickOutside=this.clickOutside.bind(this);
    }
    clickOutside(a){
            this.state.clickedOutside=true;
    }
    handleChangeOption=(event) =>{
        console.log("tyyhffdgjjjtytyytyy")
        this.setState({
            size: event.target.value
        });
        console.log(event.target.value);
        var showAddress=<DeliveryAddress/>;
        if(event.target.value=="address"){
            this.setState({showAddressOption:true});
        }
        if(event.target.value=="point"){
            this.setState({showAddressOption:false});
        }
    }
        handleTypeChange(e){
            console.log("typeChange");

                if(this.state.showAddressOption==true){
                    this.setState({showAddressOption:false});
                    this.setState({changeName:'select a point below to pick from us or'});
                    this.setState({changeImage:'img/icons/paid.png'});

                }
                else{
                     this.setState({showAddressOption:true});

                    this.setState({changeName:'enter new address or'});
                    this.setState({changeImage:'img/icons/free.png'});
}
        }



    render(){

        var deliveryType;

        if(this.state.showAddressOption){
            deliveryType=<DeliveryAddress clickedOutsideprops={this.state.clickOutside} offerId={this.props.offerId}  categoryId={this.props.categoryItem.category_id} renderFromChild={this.props.renderFromChild} orderIndexadd={this.props.orderIndex} categoryIndexadd={this.props.categoryIndex}/>;
        }
        else{
            deliveryType=<PickUpPoint defaultPoint={this.props.defaultPoint} renderFromChild={this.props.renderFromChild} orderIndexpick={this.props.orderIndex} categoryIndexpick={this.props.categoryIndex}/>;
        }
        return(


<Fragment>

    <div className="space-custom-fullcategory"></div>
    <div className='deep-font padding-left-3'>{GetOfferName(this.props.offerId)} {GetCategoryName(this.props.offerId,this.props.categoryItem.category_id) } { GetOfferDate(this.props.offerId)}</div>
    <div className="space-custom-fullcategory"></div>
    <hr/>
            <div className="row">


                <div className="col l6">
                   <div className="padding-left-3">
                       <div className="space-custom"></div>
                       <div className="font-1rem padding-left-3">
                           {this.state.changeName} <a className="clickable" onClick={(e)=>this.handleTypeChange(e)}>click here</a>
                       </div>
                       {/*<div className="space-custom"></div>*/}
                       {/*<div className="font-1rem">*/}
                       {/*    or click below*/}
                       {/*</div>*/}
                       {/*<div className="space-custom"></div>*/}
                       {/*<div className="deep-font">Select Delivery type</div>*/}
                       {/*<button className="btn btn-small green accent-4" onClick={(e)=>this.handleTypeChange(e)}>{this.state.changeName}</button>*/}
                       {/*<div className="custom-wide-button-small" onClick={(e)=>this.handleTypeChange(e)}>*/}

                       {/*                                 <span className="custom-button-text-small">*/}
                       {/*                                        {this.state.changeName}*/}
                       {/*                                     </span>*/}

                       {/*                                     <span className="button-image-container-right">*/}
                       {/*                                         <img className="button-image-right" src={this.state.changeImage}/>*/}
                       {/*                                     </span>*/}

                       {/*</div>*/}
                       {/*<div className="space-custom"></div>*/}
                       {deliveryType}
                   </div>
                </div>





                <div className="col l6">

                    <div>Your Order</div>
                    <div className="space-custom"></div>
                    {this.props.categoryItem.type_arr.map(typeItem=>(
                        <FullBasketType calculateTotal={this.props.calculateTotal} handleRemoveFromCart={this.props.handleRemoveFromCart} handleAddToCart={this.props.handleAddToCart} offerId={this.props.offerId} categoryId={this.props.categoryItem.category_id} typeItem={typeItem}/>

                    ))}

                    <hr/><div className="space-custom-fullbasket"></div>
                    <table className="memo-food">
                        <tr>
                            <td className="table-space">Delivery Charge</td> <td className="table-space memo-bottom-table">0 BDT</td>
                        </tr>
                        <tr>
                            <td className="table-space">Discount</td> <td className="table-space memo-bottom-table">0 BDT</td>
                        </tr>
                        <tr className="memo-category">
                            <td className="table-space">Subtotal</td> <td className="table-space memo-bottom-table">{this.props.categoryItem.categorytotal} BDT</td>
                        </tr>
                    </table>
                </div>

            </div>


</Fragment>

        );
    }
}
export default FullBasketCategory;