import React,{Component} from 'react';
import BasketType from "./BasketType";
import {GetOfferName} from "../services/GetOfferName";
import {GetCategoryName} from "../services/GetCategoryName";

class BasketCategory extends Component{
    constructor(props){
        super(props);

    }

    render(){

console.log(this.props.offerId,this.props.categoryItem.category_id);
        return(






                <div className={"category-top-speace"}>
                    {/*{console.log(this.props.categoryItem.category_id,this.props.offerid)}*/}


                    <span className='deep-font'>{GetOfferName(this.props.offerId)} {GetCategoryName(this.props.offerId,this.props.categoryItem.category_id) }</span>
                    {this.props.categoryItem.type_arr.map(typeItem=>(
                        <BasketType calculateTotal={this.props.calculateTotal} handleRemoveFromCart={this.props.handleRemoveFromCart} handleAddToCart={this.props.handleAddToCart} offerId={this.props.offerId} categoryId={this.props.categoryItem.category_id} typeItem={typeItem}/>

                    ))}

                </div>




        );
    }
}
export default BasketCategory;