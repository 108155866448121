import {SearchOrderIndex} from "./SearchOrderIndex";
import {SearchCategoryIndex} from "./SearchCategoryIndex";
import {SearchTypeIndex} from "./SearchTypeIndex";
import {SearchProductIndex} from "./SearchProductIndex";
import AlertOne from "../AlertOne";

export function AddToCart (orderId, catId,typeId,product){
    console.log(product);
    if(product.pricequantity==0){
        alert("Sold Out");

    }

    else {

        console.log(product);
        console.log("clicked successfully in add to cart");
        var totalPrice=0;

        var order={};
        var order1=[];
        console.log((JSON.parse(localStorage.getItem('cartItems'))));
        if((JSON.parse(localStorage.getItem('cartItems')))!=null){
            console.log("found");
            order =JSON.parse(localStorage.getItem('cartItems'));


        }
        else{

            order.list=order1;
        }
        console.log(order);

        console.log(order);
        // console.log(this.getAttribute('data-i'));
        var orderIndex=SearchOrderIndex(order.list,orderId);
        console.log("orderindex="+orderIndex);
        if(orderIndex == -1 ){
            // localStorage.setItem('cartItems',null);
            console.log("different");
            product.quantity=1;
            product.priceQuantity=product.price*product.quantity;
            var catTotalPrice=product.priceQuantity;
            var order_obj = {
                order_id: orderId, category_arr: [
                    {
                        category_id: catId,
                        type_arr: [
                            {
                                type_id: typeId,
                                product_arr: [
                                    product
                                ]
                            }
                        ]
                    }
                ]
            }
            order.list.push(order_obj);
            console.log(order);
        }
        else{
            console.log("orderindex="+orderIndex);
            console.log("offerdup");
            var orderElement=order.list[orderIndex];
            console.log(orderElement);
            var category_array=orderElement.category_arr;
            console.log(category_array);
            var categoryIndex=SearchCategoryIndex(category_array,catId);
            console.log("categoryindex="+categoryIndex);
            //
            if(categoryIndex==-1){
                product.quantity=1;
                product.priceQuantity=product.price*product.quantity;
                var category_element = {
                    category_id: catId,
                    type_arr: [
                        {
                            type_id: typeId,
                            product_arr: [
                                product
                            ]
                        }
                    ]
                }
                category_array.push(category_element);
                orderElement.category_arr=category_array;
                order.list[orderIndex]=orderElement;
            }
            else{
                console.log("categorydup");
                var category_element=category_array[categoryIndex];
                var type_array=category_element.type_arr;
                var typeIndex=SearchTypeIndex(type_array,typeId);
                console.log("typeindex="+typeIndex);
                if(typeIndex==-1){
                    product.quantity=1;
                    product.priceQuantity=product.price*product.quantity;
                    var type_element={
                        type_id: typeId,
                        product_arr: [
                            product
                        ]
                    }
                    type_array.push(type_element);
                    category_element.type_arr=type_array;

                    // category_array.push(category_element);
                    category_array[categoryIndex]=category_element;
                    orderElement.category_arr=category_array;
                    order.list[orderIndex]=orderElement;
                }
                else{
                    console.log("typedupdup");
                    var type_element=type_array[typeIndex];
                    var product_array=type_element.product_arr;
                    console.log(product.productid);
                    var product_id=product.sizepriceid;
                    var product_index=SearchProductIndex(product_array,product_id);
                    var product_element=product_array[product_index];
                    console.log("productindex="+product_index);
                    console.log(product_index);
                    if(product_index==-1){
                        console.log("productorg");
                        product.quantity=1;
                        product.priceQuantity=product.price*product.quantity;
                        product_array.push(product);
                    }
                    else{
                        console.log("productElse");
                        var product_element=product_array[product_index];
                        console.log(product_element);
                        if(product.pricequantity>product_element.quantity){
                            product_element.quantity++;
                        }
                        else{
                            alert("out of stock");
                        }

                        var singlePrice=product_element.price*product_element.quantity;
                        product_element.priceQuantity=singlePrice;
                        // totalPrice+=singlePrice;
                        product_array[product_index]=product_element;


                    }
                    type_element.product_arr=product_array;
                    type_array[typeIndex]=type_element;
                    category_element.type_arr=type_array;
                    category_array[categoryIndex]=category_element;
                    orderElement.category_arr=category_array;
                    // var product_element=product_array[product_index];
                    // totalPrice=product_element.priceQuantity;
                    // orderElement.orderTotalPrice=totalPrice;
                    order.list[orderIndex]=orderElement;

                }

            }
        }



        console.log(order);
        // this.setState({orderState:order});
        // this.setState({totalPrice:totalPrice});
        // this.state.orderState=order;
        // console.log(this.state.orderState);
        localStorage.setItem('cartItems', JSON.stringify(order));


    }


}