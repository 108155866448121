import React,{Component,Fragment} from 'react';
import './BasketCart.css'
import './basketproduct.css';
import {AddToCart} from "../services/AddToCart";
class BasketProduct extends Component{
    constructor(props){
        super(props);
        this.calculateItemTotal=this.calculateItemTotal.bind(this);
        

    }
    calculateItemTotal(price,quantity){
            return price*quantity;
    }

    render(){

        console.log(this.props.productItem);
        return(



            <Fragment>







                    <div className={"product-space"}>
                        <table>
                            <tr className='table-row-tr'>
                                <td className='image-table-td' >
                                    <img className='basket-image' src={this.props.productItem.imagepath}/>
                                </td>

                                <td>
                                    <div className='cart-quantity-name no-text-overflow'>{this.props.productItem.productname} ({this.props.productItem.size})
                                    </div>
                                    <div className='cart-quantity-name'>
                                        {/*<button onClick={(e) => this.props.handleRemoveFromCart(e, this.props.offerId, this.props.categoryId,this.props.typeId, this.props.productItem)} className='red'>*/}
                                        {/*<span className='icon-style' >*/}
                                        {/*    <i className='material-icons mat-icon'>remove</i>*/}
                                        {/*</span>*/}
                                        <span className={"add-remove-icon"} onClick={(e) => this.props.handleRemoveFromCart(e, this.props.offerId, this.props.categoryId,this.props.typeId, this.props.productItem)}>
                                            <img src={'img/icons/substract.png'}/>
                                        </span>
                                        {/*</button>*/}
                                        <span className='item-qty'>{this.props.productItem.quantity}</span>
                                        <span className={"add-remove-icon"}  onClick={(e) => this.props.handleAddToCart(e,this.props.offerId, this.props.categoryId,this.props.typeId, this.props.productItem)}>
                                        <img src={'img/icons/add.png'}/>
                                    </span>
                                        {/*<button  className="red" onClick={(e) => this.props.handleAddToCart(e,this.props.offerId, this.props.categoryId,this.props.typeId, this.props.productItem)}><span className='icon-style'><i className='material-icons mat-icon'>add</i></span></button>*/}
                                    </div>
                                </td>
                                <td className='cart-quantity-name'>
                                    { this.props.productItem.priceQuantity} BDT
                                </td>

                            </tr>
                        </table>

                    </div>



            </Fragment>


        );
    }
}
export default BasketProduct;