import React,{Component,Fragment} from 'react';
import './fullbasket.css';
import DetailsHeader from "./details/DetailsHeader";
import FullBasketCategory from "./fullBasket/FullBasketCategory";
import {Link,Redirect} from "react-router-dom";
import {PostData} from "./services/PostData";
import {CompleteOrder} from "./services/CompleteOrder";
import {ShowDeliveryPoint} from "./services/ShowDeliveryPoint";
import {VerifyOtp} from "./services/VerifyOtp";
import {ShowDeliveryTime} from "./services/ShowDeliveryTime";
import {SearchOrderIndex} from "./services/SearchOrderIndex";
import {AddToCart} from "./services/AddToCart";
import {RemoveFromCart} from "./services/RemoveFromCart";
import { withRouter } from "react-router-dom";
import { browserHistory } from 'react-router';
import DeliveryAddress from "./DeliveryAddress";
import PickUpPoint from "./PickUpPoint";
import M from "materialize-css";
import AlertOne from "./AlertOne";

class FullBasket extends Component{
	constructor(props){
		super(props);
        this.state = {
            points:[],
            times:[],
            post:[],
            size:'',
            checkedRadio:false,
            display:true,
            point_selected:true,
            time_selected:true,
            address_selected:false,
            area_selected:false,
            city_selected:false,
            region_selected:false,
            timerange_selected:false,
            showAddressOption:false,
            reRender:false,
            date: new Date().toLocaleString(),
            setDefaultPoint:null,
            message:'This is your full order',
            allAddressFound:false,
            allTimeFound:false,
            validation:false

        };
        console.log(this.state.size);

        this.handleClick=this.handleClick.bind(this);

        this.handleAddToCart=this.handleAddToCart.bind(this);
        this.handleRemoveFromCart=this.handleRemoveFromCart.bind(this);
        this.calculateTotal=this.calculateTotal.bind(this);

        this.redirect=this.redirect.bind(this);
        this.handleChangeOption=this.handleChangeOption.bind(this);
        this.renderFromChild=this.renderFromChild.bind(this);


	}
    componentDidUpdate() {

        // let collapsible = document.querySelectorAll(".collapsible");
        //
        // M.Collapsible.init(collapsible, {});
        var elems = document.querySelectorAll('.scrollspy');
         M.ScrollSpy.init(elems, {});

    }
    // componentDidMount(){
    //     // window.scrollTo(0, 0);
    // }

    handleChangeOption=(event) =>{
	    // console.log("tyyhffdgjjjtytyytyy");
        this.setState({
            size: event.target.value
        });
        console.log(event.target.value);
        var showAddress=<DeliveryAddress/>;
        if(event.target.value=="address"){
            this.setState({showAddressOption:true});
        }
        if(event.target.value=="point"){
            this.setState({showAddressOption:false});
        }
    }


    timerangeInput(e,orderIndexf,categoryIndexf){
        console.log("addressinput");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({timerange_selected:true});
        }
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].time=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
	handleDisplayInFullBasket=(e)=>{

    }
    handleAddToCart=(e,orderId,categoryId,typeId,product)=>{
	    AddToCart(orderId,categoryId,typeId,product);
        this.setState({display:true});
    }
    handleRemoveFromCart=(e,orderId,categoryId,typeId,product)=>{
	    RemoveFromCart(orderId,categoryId,typeId,product);
        this.setState({display:true});
    }

    componentDidMount(){
        M.AutoInit();
        window.scrollTo(0, 0);
        ShowDeliveryPoint().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({points:responseJson})

        });
        ShowDeliveryTime().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({times:responseJson})

        });





    }
    redirect=()=>{
        console.log("redirect");
        // localStorage.clear();
        this.props.history.push('/paymentmethod');
    }
    renderFromChild(val){
	    console.log(val);
	   this.setState({setDefaultPoint:val});
        console.log(this.state.setDefaultPoint);
    }

    calculateTotal=()=>{
        var cartItems1=JSON.parse(localStorage.getItem('cartItems'));
        console.log(cartItems1);
        var preorder=cartItems1.list;
        var total=0;
        var i;
        var j;
        var k;
        var l;
        for(i in preorder){

            for (j in preorder[i].category_arr){

                for(k in preorder[i].category_arr[j].type_arr){
                    var  ordertotal=0;
                    var vproductarray=preorder[i].category_arr[j].type_arr[k].product_arr;
                    for(l in vproductarray){
                        ordertotal+=vproductarray[l].priceQuantity;
                        console.log(ordertotal)
                    }
                    preorder[i].category_arr[j].categorytotal=ordertotal;
                    total+=ordertotal;
                }

            }
            // order.finalordertotalprice=total;
        }

        // localStorage.setItem('orderTotal', JSON.stringify(total));
        // console.log(order);
        var order={};
        order.list=preorder;
        order.finalordertotalprice=total;
        // order['totalprice']=total;
        localStorage.setItem('cartItems', JSON.stringify(order));
        // return order;
        console.log(JSON.parse(localStorage.getItem('cartItems')))
        //   this.state.totalPriceState=total;
        // console.log(this.state.totalPriceState);
    }


    handleClick = () => {
        var order = {};
        order= JSON.parse(localStorage.getItem('cartItems'));
        order.token = JSON.parse(localStorage.getItem('session'));
        // order.total = JSON.parse(localStorage.getItem('orderTotal'));
        console.log(JSON.stringify(order));

        // var cartItems1=JSON.parse(localStorage.getItem('cartItems'));
        // console.log(cartItems1);
        var preorder=order.list;
        var total=0;
        var i;
        var j;
        var k;
        var l;
        var validateAddressPoint=false;
        var validateAddress=false;
        var validateTime=false;
        var demo=0;
        for(i in preorder){

            for (j in preorder[i].category_arr){
                console.log(preorder[i].category_arr[j]);

                if(preorder[i].category_arr[j]['time_id']==undefined){
                    // this.setState({validation:false});
                    validateTime=false;
                    demo++;
                    console.log(demo);
                }
                else{
                    // this.setState({validation:true});
                    if(demo>0){
                        validateTime=false;
                    }
                    else{
                        validateTime=true;
                    }


                }

                if(preorder[i].category_arr[j]['point_id']==undefined){
                    // this.setState({validation:false});
                    validateAddressPoint=false;
                }
                else if (preorder[i].category_arr[j]['user_address_id']==undefined){
                    // this.setState({validation:true});
                    validateAddress=false;

                }

                else if (preorder[i].category_arr[j]['address']==undefined){
                    // this.setState({validation:true});
                    validateAddress=false;

                }
                else if (preorder[i].category_arr[j]['area']==undefined){
                    // this.setState({validation:true});
                    validateAddress=false;

                }
                else if (preorder[i].category_arr[j]['city']==undefined){
                    // this.setState({validation:true});
                    validateAddress=false;

                }
                else if (preorder[i].category_arr[j]['region']==undefined){
                    // this.setState({validation:true});
                    validateAddress=false;

                }
                else{
                    validateAddressPoint=true;
                }

            }
            // order.finalordertotalprice=total;
        }
        console.log(preorder);
console.log(validateTime);

       if(validateTime && (validateAddress || validateAddressPoint)){
           CompleteOrder(order).then((result) => {
               let responseJson = result;
               console.log(responseJson);
               console.log(responseJson['status']);
               if(responseJson['status']=="successfull"){
                   var orderid=responseJson['resp_order_id'];
                    localStorage.setItem('orderId',JSON.stringify(orderid));


                   var cartPayment=JSON.parse(localStorage.getItem('cartItems'));
                   window.localStorage.removeItem("cartItems");
                   // localStorage.setItem('cartItems',{});
                   localStorage.setItem('cartItemsPayment',JSON.stringify(cartPayment));

                    // localStorage.setItem('cartItems',undefined);
                   // this.props.hostory.push('/paymentmethod');
                   // alert("order successfull");
                // 
                // return( <Redirect to='/paymentmethod'/>);
                // <Payment/>
                this.redirect();
               }
               else{
                   // sessionStorage.setItem('userData',JSON.stringify(responseJson));
                   // this.props.hostory.push('/paymentmethod');
                   alert("order not completed");

               }

               // console.log(this.state);
           });
       }
       else{
           alert("plese select address and time")
       }
    }


    render(){
	    if(this.state.showAddressOption){
            var addressdisplay="addressview";
            var pointdisplay="nopointview";
        }
	    else{
            var addressdisplay="noaddressview";
            var pointdisplay="pointview";
        }

        this.calculateTotal();
        var cartItems1=JSON.parse(localStorage.getItem('cartItems'));

        var total=cartItems1.finalordertotalprice;
        var cartItems=cartItems1.list;
        // console.log(JSON.parse(localStorage.getItem('session'))=="false");
        // console.log("yepp");
        console.log(cartItems);
        const points=this.state.points;
        const times=this.state.times;
        // console.log(typeof points);


        if((this.state.display) || (this.state.reRender)){
            return(
                <div >
                    <AlertOne message={this.state.message}/>

                    <div className="container">

                        <div className="details-header">
              <span className="back-button">

                      <Link to="/"><div className="back-image"><img src="img/icons/back.png"/></div></Link>
                    </span>
                            <span className="button-text">
                      back to main
                    </span>


                        </div>

                    </div>

                    <div class="full-basket-container">


                        <div className="containe">
                            {/*<div className="enable-basket">*/}
                            <div className="">



                                {cartItems.map((cartItem,orderIndex)=> (
                                    <Fragment>
                                        {/*<p><span class="green-text">{this.getOfferName(cartItem.order_id)}</span></p>*/}

                                        {cartItem.category_arr.map((categoryItem,categoryIndex) => (

                                            <div className="seperate-order">
                                                <div className="container-small">
                                                    <div className="margin-bottom">







                                                        <div className="c">

                                                            <FullBasketCategory  calculateTotal={this.calculateTotal}
                                                                                 orderIndex={orderIndex}
                                                                                 categoryIndex={categoryIndex}
                                                                                handleRemoveFromCart={this.handleRemoveFromCart}
                                                                                handleAddToCart={this.handleAddToCart}
                                                                                offerId={cartItem.order_id}

                                                                                categoryItem={categoryItem}
                                                                                 renderFromChild={this.renderFromChild}
                                                                                 defaultPoint={this.state.setDefaultPoint}
                                                            />


                                                            <div className="space-custom-fullbasket"></div>
                                                            {/*<span className="deep-font text-allign-right">Subtotal ={categoryItem.categorytotal} BDT</span>*/}

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        ))}

                                    </Fragment>
                                ))}



                                {/*<button onClick={this.redirectToFullBasket} className="btn green accent-4" type="submit"*/}
                                {/*        name="action">Go To Checkout*/}
                                {/*    /!*<Link to={{*!/*/}
                                {/*    /!*    pathname: '/login'*!/*/}
                                {/*    /!*}}>Full Basket</Link>*!/*/}
                                {/*</button>*/}
                                <div className="seperate-order">
                                    <div className="container-small">
                                        {/*<div className="deep-font">Total Price ={total} BDT</div>*/}
                                        <button onClick={this.handleClick} className="btn green accent-4 order-large-button">Go To Payment</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            );
        }
        else{

            return (
                <div className="container">
                    <div className="details-header">
              <span className="back-button">

                      <Link to="/"><i className="fas fa-chevron-left"></i></Link>
                    </span>
                        <span className="button-text">
                      back to main
                    </span>


                    </div>
error
                </div>


            );
        }
    }
}
export default FullBasket;