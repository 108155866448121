import React,{Component,Fragment} from 'react';
import BasketCategory from "./BasketCategory";
import BasketProduct from "./BasketProduct";
class BasketType extends Component{
    constructor(props){
        super(props);
    }

    render(){


        return(



            <Fragment>






                    {this.props.typeItem.product_arr.map(productItem=>(
                       <BasketProduct calculateTotal={this.props.calculateTotal} handleRemoveFromCart={this.props.handleRemoveFromCart} handleAddToCart={this.props.handleAddToCart} offerId={this.props.offerId} categoryId={this.props.categoryId} typeId={this.props.typeItem.type_id} productItem={productItem}/>

                    ))}



            </Fragment>


        );
    }
}
export default BasketType;