export function GetOfferDate(offerId) {
    var items=JSON.parse(localStorage.getItem('apiData'));
    var offerdate;
    // return "today";

    // console.log(offerId);
    for(var i=0; i<items.length; i++){

        if(items[i].offer_id==offerId){
            offerdate=items[i].offer_date;
            // console.log(offername);
            return offerdate;
        }
    }
}