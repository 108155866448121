export function  SearchProductIndex(offerArr,orderId){
    var orderVar=offerArr;
    var obj=orderVar.find(function (x) {
            return x.sizepriceid==orderId;
        }

    );
    var index=-1;
    if (obj!=undefined)
        index=orderVar.indexOf(obj);
    return index;
}