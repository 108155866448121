import React,{Component,Fragment} from 'react';
import {ShowDeliveryPoint} from "./services/ShowDeliveryPoint";
import {ShowDeliveryTime} from "./services/ShowDeliveryTime";
import {ShowUserAddress} from "./services/ShowUserAddress";
import "./deliveryaddress.css";
import M from "materialize-css";
import {AllAddressName} from "./services/AllAddressName";
import {AllAddressArea} from "./services/AllAddressArea";
import {AllAddressCity} from "./services/AllAddressCity";
import {AllAddressRegion} from "./services/AllAddressRegion";
import AlertOne from "./AlertOne";

// import 'materialize-css/dist/css/materialize.min.css'

class DeliveryAddress extends Component{
    constructor(props){
        super(props);
        this.state={
            text:'',
            area:null,
            city:null,
            region:null,
            myAddressName:null,
            userAddress:[],
            allAddressName:[],
            allAddressArea:[],
            allAddressCity:[],
            allAddressRegion:[],
            times:[],
            userAddressSelected:true,
            inputselect:'suggestion-hide',
            address_selected:false,
            noSuggestion:'suggestion',
            noaddressname:'hide-address-name',
            savedaddress:'suggestion',
            showarea:'suggestion-hide-small',
            showcity:'suggestion-hide-small',
            showregion:'suggestion-hide-small'
        }
        this.addressInput=this.addressInput.bind(this);
        this.addressInputSelected=this.addressInputSelected.bind(this);
        this.timeSelected=this.timeSelected.bind(this);

        this.saveAddressCheck=this.saveAddressCheck.bind(this);
        this.yourAreaInput=this.yourAreaInput.bind(this);

    }

    componentDidUpdate() {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems, {});

        // var autocomplete = document.getElementById('.favefunction');
        // M.Autocomplete.init(autocomplete,alldata);
        // var instance = M.Autocomplete.getInstance(autocomplete);
        // instance.open();
    }
    saveAddressCheck(e,orderIndex,categoryIndex,index){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var usraddressid=target.value;
        console.log(name);
        console.log(value);
        console.log(target.value);
        var alladdress=this.state.userAddress;
        console.log(usraddressid.length);
        console.log(index);
        var i;
        var addressmain;
        for(i=0;i<alladdress.length;i++){
            // console.log(alladdress.length);
            if((alladdress[i].user_address_id)==usraddressid){
                this.state.area=alladdress[i].area;
                this.state.city=alladdress[i].city;
                this.state.region=alladdress[i].region;
                addressmain=alladdress[i].user_address;
            }
        }

        this.setState({text:addressmain});
        this.setState({inputselect:'suggestion-hide'});

        console.log(orderIndex,categoryIndex);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndex].category_arr[categoryIndex].user_address_id=usraddressid;
        order.list[orderIndex].category_arr[categoryIndex].point_id=-1;
        order.list[orderIndex].category_arr[categoryIndex].address=-1;
        order.list[orderIndex].category_arr[categoryIndex].area=-1;
        order.list[orderIndex].category_arr[categoryIndex].region=-1;
        order.list[orderIndex].category_arr[categoryIndex].city=-1;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));
    }

    addressInput(e,orderIndexf,categoryIndexf){
        console.log("addressinput");
        console.log(e.target.value);
        this.setState({text:e.target.value});
        if(e.target.value.length>=1 && e.target.value.length<=2){
            this.setState({noaddressname:'show-address-name'});
            this.setState({inputselect:'suggestion-show'});
            this.setState({address_selected:true});
            // alert("give a name");
           
        }
        else{
            this.setState({inputselect:'suggestion-hide'});
        }
        this.setState({noaddressname:'show-address-name'});
        this.setState({savedaddress:'no-suggestion'});
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].address=e.target.value;
        order.list[orderIndexf].category_arr[categoryIndexf].point_id=-1;
        order.list[orderIndexf].category_arr[categoryIndexf].user_address_id=-1;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    addressInputSelected(e,index,id,orderIndex,categoryIndex){
        console.log(id);
        this.setState({text:index});
       var alladdress=this.state.userAddress;
       console.log(alladdress);

       var i;
        for(i=0;i<alladdress.length;i++){
            if((alladdress[i].user_address_id)==id){
                this.state.area=alladdress[i].area;
                this.state.city=alladdress[i].city;
                this.state.region=alladdress[i].region;
            }
        }
        console.log(this.state.area);
        this.setState({inputselect:'suggestion-hide'});
        this.setState({noaddressname:'hide-address-name'});

        console.log(orderIndex,categoryIndex);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndex].category_arr[categoryIndex].user_address_id=id;
        order.list[orderIndex].category_arr[categoryIndex].point_id=-1;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));
    }
    timeSelected(e,orderIndexf,categoryIndexf){
        console.log("time select");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({time_selected:true});
        }
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].time_id=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }

    yourAreaInput(e,orderIndexf,categoryIndexf){
        console.log("addressinput");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({area_selected:true});
        }
        this.setState({userAddressSelected:false});
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].user_address_type_id=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    yourAreaNotSelected(orderIndex,categoryIndex){
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndex].category_arr[categoryIndex].user_address_type_id=1;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    areaInput(e,orderIndexf,categoryIndexf){
        console.log("addressinput");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({area_selected:true});
            this.setState({showregion:'suggestion-hide-small'});
            this.setState({showcity:'suggestion-hide-small'});
        }

        if(e.target.value.length>=0 && e.target.value.length<2){
            this.setState({showarea:'suggestion-show-small'});
            this.setState({showregion:'suggestion-hide-small'});
            this.setState({showcity:'suggestion-hide-small'})

            // alert("give a name");

        }
        else{
            this.setState({showarea:'suggestion-hide-small'});
        }

        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].area=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }

    areaInputSelected(e,area){
        console.log(e);
        this.setState({area:area});
        this.setState({showarea:'suggestion-hide-small'});
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[this.props.orderIndexadd].category_arr[this.props.categoryIndexadd].area=area;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    cityInputSelected(e,city){
        console.log(e);
        this.setState({city:city})
        this.setState({showarea:'suggestion-hide-small'});
        this.setState({showcity:'suggestion-hide-small'});
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[this.props.orderIndexadd].category_arr[this.props.categoryIndexadd].city=city;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    regionInputSelected(e,region){
        console.log(e);
        this.setState({region:region});
        this.setState({showregion:'suggestion-hide-small'});
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[this.props.orderIndexadd].category_arr[this.props.categoryIndexadd].region=region;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }

    cityInput(e,orderIndexf,categoryIndexf){
        console.log("cityinput");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({city_selected:true});
            this.setState({showarea:'suggestion-hide-small'});
            this.setState({showregion:'suggestion-hide-small'});
        }
        if(e.target.value.length>=0 && e.target.value.length<2){
            this.setState({showcity:'suggestion-show-small'});
            this.setState({showarea:'suggestion-hide-small'});
            this.setState({showregion:'suggestion-hide-small'});

            // alert("give a name");

        }
        else{
            this.setState({showcity:'suggestion-hide-small'});
        }
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].city=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    regionInput(e,orderIndexf,categoryIndexf){
        console.log("addressinput");
        console.log(e.target.value);
        if(e.target.value){
            this.setState({point_selected:true});
            this.setState({showarea:'suggestion-hide-small'});
            this.setState({showcity:'suggestion-hide-small'});
        }
        if(e.target.value.length>=0 && e.target.value.length<2){
            this.setState({showregion:'suggestion-show-small'});
            this.setState({showarea:'suggestion-hide-small'});
            this.setState({showcity:'suggestion-hide-small'});


            // alert("give a name");

        }
        else{
            this.setState({showregion:'suggestion-hide-small'});
        }
        console.log(orderIndexf,categoryIndexf);
        var order=JSON.parse(localStorage.getItem('cartItems'));
        order.list[orderIndexf].category_arr[categoryIndexf].region=e.target.value;
        // localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(order);
        localStorage.setItem('cartItems',JSON.stringify(order));
        console.log(JSON.parse(localStorage.getItem('cartItems')));

    }
    componentDidMount(){
        var showUserAddress={};
        showUserAddress.token = JSON.parse(localStorage.getItem('session'));
        ShowUserAddress(showUserAddress).then((result) => {
            let responseJson = result;
            console.log(result);

           if((result.length)==0){
               console.log("failed");

               this.setState({noSuggestion:'suggestion'});
           }
            else{
                console.log("success");
               this.setState({userAddress:responseJson});
               console.log(this.state.userAddress);
               this.setState({noSuggestion:'no-suggestion'});
           }




        });


        AllAddressName().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({allAddressName:responseJson});
            console.log(this.state.allAddressName);

        });
        AllAddressArea().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({allAddressArea:responseJson});
            console.log(this.state.allAddressArea);

        });

        AllAddressCity().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({allAddressCity:responseJson});
            console.log(this.state.allAddressCity);

        });
        AllAddressRegion().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({allAddressRegion:responseJson});
            console.log(this.state.allAddressRegion);

        });

        if(this.props.clickedOutsideprops){
            this.setState({inputselect:'suggestion-hide'});
            console.log("sdfgasdgasdgasdv");
        }

        ShowDeliveryTime().then((result) => {
            let responseJson = result;
            console.log(responseJson);
            this.setState({times:responseJson})

        });





    }

    render(){
        const text=this.state.text;
        const times=this.state.times;
        const suggest=this.state.userAddress;
        console.log(text);
        console.log(suggest);
        const inputselected=this.state.inputselect;
        const suggestion="padding5";
        var allAddressName=this.state.allAddressName;
        var allAddressArea=this.state.allAddressArea;
        var allAddressCity=this.state.allAddressCity;
        var allAddressRegion=this.state.allAddressRegion;
        var showArea=this.state.showarea;
        var showCity=this.state.showcity;
        var showRegion=this.state.showregion;

        if(this.state.userAddressSelected){
            this.yourAreaNotSelected(this.props.orderIndexadd,this.props.categoryIndexadd);
        }


        // handleClick() {
        //     this.setState ({this.state.random + this.rand})
        // }


        return(
            <div className="">


                <div className="row">

                    <div className="col l12">
                        {/*<div className="space-custom"> </div>*/}
                        <div className="row">
                            {/*<div className="col l6">*/}
                            {/*    <div className="padding5 font-1rem">*/}
                            {/*        select saved address*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="">
                               <div className={this.state.savedaddress}>
                                   {/*<div className="font-1rem"> select from previous</div>*/}
                                   <div className="space-custom"> </div>
                                   {suggest.map((addressname,index)=>
                                       <span className="right-pad-20">


                                           {/*{""+addressname['user_address_id']+""+this.props.offerId+""+this.props.categoryId+""}*/}
                                        <input type="checkbox" name={addressname['user_address_type']} value={addressname['user_address_id']} id={""+addressname['user_address_id']+""+this.props.offerId+""+this.props.categoryId+""} disabled={false} onChange={(e)=>this.saveAddressCheck(e,this.props.orderIndexadd,this.props.categoryIndexadd,index)}/>
                                        <label htmlFor={""+addressname['user_address_id']+""+this.props.offerId+""+this.props.categoryId+""}>{addressname['user_address_type']}</label>
                                    </span>
                                   )}

                               </div>
                            </div>


                                    {/*    <div className="suggestion">*/}
                                    {/*        <div className="font-1rem"> or enter new address</div>*/}
                                    {/*    <div className="input-field autocomplete">*/}

                                    {/*        <input placeholder="Home"  value={ null || this.state.myAddressName} type="text"  onChange={(e) =>this.yourAreaInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)}/>*/}
                                    {/*        /!*<label htmlFor="first_name">Area</label>*!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                        <div className="">
                                           <div className={this.state.noaddressname}>
                                               <label>Give a name to your address eg: Home ,Office</label>

                                              <div className={"width"}>
                                                  <select className="width" onChange={(e) =>this.yourAreaInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)} required>

                                                      {allAddressName.map(addressname=>
                                                          <option value={addressname['address_name_id']}>{addressname['address_name']}</option>)}

                                                  </select>
                                              </div>
                                           </div>
                                        </div>





                        </div>


                     <div className="input-suggest-wrapper">
                         <div className="input-field autocomplete">

                             <input  placeholder="House no sector no & road no" type="text" value={this.state.text||null}  onChange={(e) =>this.addressInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)}/>

                         </div>
                         <div className={inputselected}>
                             {
                                 suggest.map((useradd,index)=>(
                                     <Fragment>

                                         <li onClick={(e) =>this.addressInputSelected(e,useradd['user_address'],useradd['user_address_id'],this.props.orderIndexadd,this.props.categoryIndexadd)} className="suggest-row"><span className="place-image-container"><img src={'img/icons/place.png'}/></span><span className="place-suggestion-text">{useradd['user_address']}</span></li>
                                     </Fragment>
                                 ))
                             }
                         </div>
                     </div>
                    </div>
                    <div className="col l4">
                        <div className="input-field autocomplete">

                            <input placeholder="area"  value={this.state.area||null} type="text"  onFocus={(e) =>this.areaInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)} onChange={(e) =>this.areaInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)}/>
                            {/*<label htmlFor="first_name">Area</label>*/}
                        </div>
                        <div className={showArea}>

                            {
                                allAddressArea.map((singlearea,index)=>
                                    <Fragment>

                                        <li onClick={(e) =>this.areaInputSelected(e,singlearea['address_area'])}  className="suggest-row-small">{singlearea['address_area']}</li>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                    <div className="col l4">
                        <div className="input-field">
                            <input placeholder="city" type="text" value={this.state.city||null} className="validate" onFocus={(e) =>this.cityInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)} onChange={(e) =>this.cityInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)}/>

                        </div>
                        <div className={showCity}>

                            {
                                allAddressCity.map((singlecity,index)=>
                                    <Fragment>

                                        <li onClick={(e) =>this.cityInputSelected(e,singlecity['address_city'])} className="suggest-row-small">{singlecity['address_city']}</li>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                    <div className="col l4">
                        <div className="input-field">
                            <input placeholder="region" type="text" value={this.state.region||null} className="validate" onFocus={(e) =>this.regionInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)} onChange={(e) =>this.regionInput(e,this.props.orderIndexadd,this.props.categoryIndexadd)}/>

                        </div>
                        <div className={showRegion}>

                            {
                                allAddressRegion.map((singleregion,index)=>
                                    <Fragment>

                                        <li onClick={(e) =>this.regionInputSelected(e,singleregion['address_region'])} className="suggest-row-small">{singleregion['address_region']}</li>
                                    </Fragment>
                                )
                            }
                        </div>
                    </div>
                    <div className="col l12">
                        <label>Select Delivery Time</label>

                            <select className="w" onChange={(e) =>this.timeSelected(e,this.props.orderIndexadd,this.props.categoryIndexadd)} required>
                            <option value="" disabled selected>Select Time Range
                            </option>
                            {times.map(time=>
                                <option value={time['delivery_time_id']}>{time['delivery_times']}</option>)}

                            </select>
                    </div>

                </div>
            </div>
        );

    }
}
export default DeliveryAddress;